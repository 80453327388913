import React, { useEffect, useState } from 'react';
import Footer from './footer'; // Importa el Footer
import Navbar from './navbar'; // Importa el Navbar
import '../styles/overwatchadmin.css'; // Asegúrate de tener este archivo de estilos

const OverwatchAdmin = () => {
    const [boosts, setBoosts] = useState([]);
    const [filteredBoosts, setFilteredBoosts] = useState([]); // Para mostrar boosts filtrados
    const [filters, setFilters] = useState({
        activo: false,
        pagado: false,
        enProgreso: false,
        completado: false,
    });

    const [showModal, setShowModal] = useState(false); // Para controlar la visibilidad del modal
    const [selectedBoost, setSelectedBoost] = useState(null); // Para almacenar el boost seleccionado
    const [showEditModal, setShowEditModal] = useState(false);
    const [editingBoost, setEditingBoost] = useState(null);
    const [editForm, setEditForm] = useState({});
    const [currentPage, setCurrentPage] = useState(1); // Página actual
    const itemsPerPage = 8; // Número de elementos por página

    // Calcular los elementos visibles según la página actual
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredBoosts.slice(indexOfFirstItem, indexOfLastItem);

    // Función para cambiar de página
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    // Calcular el número total de páginas
    const totalPages = Math.ceil(filteredBoosts.length / itemsPerPage);

    useEffect(() => {
        const fetchBoosts = async () => {
            try {
                const response = await fetch('https://alienboost.onrender.com/boosts-en-carrito');
                const data = await response.json();
                setBoosts(data.boosts || []);
                setFilteredBoosts(data.boosts || []); // Inicialmente muestra todos los pedidos
            } catch (error) {
                console.error('Error al obtener los boosts:', error);
            }
        };

        fetchBoosts();
    }, []);

    // Función para traducir el tipo de boost a su texto correspondiente
    const getBoostTypeText = (type) => {
        switch (type) {
            case 1:
                return 'Rank Boost';
            case 2:
                return 'Quickplay Boost';
            case 3:
                return 'Battlepass Boost';
            case 4:
                return 'Placement Boost';
            case 5:
                return 'Win Boost';
            default:
                return 'Desconocido';
        }
    };

    // Función para traducir el estado a su texto correspondiente
    const getBoostStateText = (estado) => {
        switch (estado) {
            case 'activo':
                return 'Activo';
            case 'pagado':
                return 'Pagado';
            case 'enProgreso':
                return 'En progreso';
            case 'completado':
                return 'Completado';
            default:
                return 'Desconocido';
        }
    };

    // Función para manejar los filtros
    const handleFilterChange = (filter) => {
        const newFilters = { ...filters, [filter]: !filters[filter] };
        setFilters(newFilters);

        // Filtrar boosts según los filtros activos
        const activeFilters = Object.keys(newFilters).filter((key) => newFilters[key]);
        if (activeFilters.length === 0) {
            setFilteredBoosts(boosts); // Mostrar todos los pedidos si no hay filtros activos
        } else {
            const filtered = boosts.filter((boost) => activeFilters.includes(boost.estado));
            setFilteredBoosts(filtered);
        }
    };

    // Función para abrir el modal con la descripción detallada
    const openModal = (boost) => {
        setSelectedBoost(boost); // Almacenar el boost seleccionado
        setShowModal(true); // Mostrar el modal
    };

    // Función para cerrar el modal
    const closeModal = () => {
        setShowModal(false); // Ocultar el modal
        setSelectedBoost(null); // Limpiar el boost seleccionado
    };

    const handleEditClick = (boost) => {
        setEditingBoost(boost);
        setEditForm({
            precio_calculado: boost.precio_calculado,
            descripcion: boost.descripcion,
            duracion: boost.duracion,
            descripcion_detallada: boost.descripcion_detallada,
            estado: boost.estado, // Añadir el estado al formulario
        });
        setShowEditModal(true);
    };
    
    const handleEditChange = (e) => {
        const { name, value } = e.target;
        setEditForm((prev) => ({ ...prev, [name]: value }));
    };

    const handleEditSubmit = async () => {
        try {
            const response = await fetch(
                `https://alienboost.onrender.com/editar-boost/${editingBoost.boost_id}`,
                {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(editForm),
                }
            );
    
            if (!response.ok) throw new Error('Error al editar el boost.');
    
            // Actualiza el estado local con los cambios realizados
            const updatedBoost = { ...editingBoost, ...editForm };
            setBoosts((prev) =>
                prev.map((boost) =>
                    boost.boost_id === editingBoost.boost_id ? updatedBoost : boost
                )
            );
    
            // También actualiza los filtros aplicados para reflejar los cambios
            setFilteredBoosts((prev) =>
                prev.map((boost) =>
                    boost.boost_id === editingBoost.boost_id ? updatedBoost : boost
                )
            );
    
            // Cierra el modal y limpia el estado del boost en edición
            setShowEditModal(false);
            setEditingBoost(null);
        } catch (error) {
            console.error('Error al editar el boost:', error);
        }
    };
    

    const handleDelete = async (boostId) => {
        const confirmDelete = window.confirm("¿Estás seguro de que quieres eliminar este boost?");
        if (!confirmDelete) {
            return;
        }

        try {
            const response = await fetch(`https://alienboost.onrender.com/borrar-boost/${boostId}`, {
                method: "DELETE",
            });

            if (response.ok) {
                const data = await response.json();
                alert(data.message || "Boost eliminado exitosamente.");
                setBoosts((prevBoosts) => prevBoosts.filter((boost) => boost.boost_id !== boostId));
                setFilteredBoosts((prevBoosts) => prevBoosts.filter((boost) => boost.boost_id !== boostId));
            } else {
                const errorData = await response.json();
                alert(errorData.detail || "Error al intentar eliminar el boost.");
            }
        } catch (error) {
            console.error("Error al eliminar el boost:", error);
            alert("Ocurrió un error al eliminar el boost.");
        }
    };

    return (
        <div className="main-container">
            <Navbar />
            <h1 className="h1boost-overwatch-admin">Boost Overwatch</h1>

            {/* Filtros */}
            <div className="filter-container">
                <label>
                    <input
                        type="checkbox"
                        checked={filters.activo}
                        onChange={() => handleFilterChange('activo')}
                    />
                    Activo
                </label>
                <label>
                    <input
                        type="checkbox"
                        checked={filters.pagado}
                        onChange={() => handleFilterChange('pagado')}
                    />
                    Pagado
                </label>
                <label>
                    <input
                        type="checkbox"
                        checked={filters.enProgreso}
                        onChange={() => handleFilterChange('enProgreso')}
                    />
                    En Progreso
                </label>
                <label>
                    <input
                        type="checkbox"
                        checked={filters.completado}
                        onChange={() => handleFilterChange('completado')}
                    />
                    Completado
                </label>
            </div>

            {/* Tabla para mostrar los boosts */}
            <table className="boosts-table-overwatch-admin">
                <thead>
                    <tr>
                        <th>Juego</th>
                        <th>Username</th>
                        <th>Descripción</th>
                        <th>Duración</th>
                        <th>Precio</th>
                        <th>Boost ID</th>
                        <th>Tipo</th>
                        <th>Estado</th>
                        <th>Detalles</th>
                        <th>Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    {currentItems.map((boost) => (
                        <tr key={boost.boost_id}>
                            <td data-label="Juego">{boost.juego}</td>
                            <td data-label="Username">{boost.username}</td>
                            <td data-label="Descripción">{boost.descripcion}</td>
                            <td data-label="Duración">{boost.duracion} Dias</td>
                            <td data-label="Precio">${boost.precio_calculado}</td>
                            <td data-label="Boost ID">{boost.boost_id}</td>
                            <td data-label="Tipo">{getBoostTypeText(boost.tipo)}</td>
                            <td data-label="Estado">{getBoostStateText(boost.estado)}</td>
                            <td data-label="Detalles">
                                <button
                                    className="details-button-overwatch-admin"
                                    onClick={() => openModal(boost)}
                                >
                                    Ver
                                </button>
                            </td>
                            <td data-label="Acciones">
                                <button onClick={() => handleEditClick(boost)}>✏️</button>
                                <button onClick={() => handleDelete(boost.boost_id)}>🗑️</button>
                            </td>
                        </tr>
                    ))}
                </tbody>


            </table>
            <div className="pagination">
                <button
                    onClick={() => paginate(currentPage - 1)}
                    disabled={currentPage === 1} // Deshabilitar si estás en la primera página
                    className="arrow-button"
                >
                    &lt; {/* Flecha izquierda */}
                </button>
                <button
                    onClick={() => paginate(currentPage + 1)}
                    disabled={currentPage === totalPages} // Deshabilitar si estás en la última página
                    className="arrow-button"
                >
                    &gt; {/* Flecha derecha */}
                </button>
            </div>



            {/* Modal para mostrar la descripción detallada */}
            {showModal && selectedBoost && (
                <div className="modal-overlay-overwatch-admin">
                    <div className="modal-overwatch-admin">
                        <h2>Descripción Detallada</h2>
                        <p>
                            {selectedBoost.descripcion_detallada 
                                ? selectedBoost.descripcion_detallada 
                                : 'No hay descripción detallada disponible.'}
                        </p>
                        <button className="close-button-overwatch-admin" onClick={closeModal}>Cerrar</button>
                    </div>
                </div>
            )}

            {showEditModal && (
                <div className="modal-overlay-overwatch-admin">
                    <div className="modal-overwatch-admin">
                        <h2>Editar Boost</h2>
                        <form>
                            <label>Precio:</label>
                            <input
                                type="number"
                                name="precio_calculado"
                                value={editForm.precio_calculado}
                                onChange={handleEditChange}
                            />
                            <label>Descripción:</label>
                            <textarea
                                name="descripcion"
                                value={editForm.descripcion}
                                onChange={handleEditChange}
                            ></textarea>
                            <label>Duración:</label>
                            <input
                                type="number"
                                name="duracion"
                                value={editForm.duracion}
                                onChange={handleEditChange}
                            />
                            <label>Descripción Detallada:</label>
                            <textarea
                                name="descripcion_detallada"
                                value={editForm.descripcion_detallada}
                                onChange={handleEditChange}
                            ></textarea>
                            <label>Estado:</label>
                            <select
                                name="estado"
                                value={editForm.estado || ''}
                                onChange={handleEditChange}
                            >
                                <option value="">Seleccionar Estado</option>
                                <option value="activo">Activo</option>
                                <option value="pagado">Pagado</option>
                                <option value="enProgreso">En progreso</option>
                                <option value="completado">Completado</option>
                            </select>
                        </form>
                        <button onClick={handleEditSubmit}>Guardar Cambios</button>
                        <button onClick={() => setShowEditModal(false)}>Cerrar</button>
                    </div>
                </div>
            )}


            <Footer />
        </div>
    );
};

export default OverwatchAdmin;
