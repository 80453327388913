/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import Navbar from './navbar';
import Modal from 'react-modal';
import '../styles/ventaow.css';

Modal.setAppElement('#root');

function VentaOW() {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [editModalIsOpen, setEditModalIsOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(1); // Página actual
    const itemsPerPage = 8; // Máximo de cuentas por página
    const [formData, setFormData] = useState({
        image: '',
        game: 'Overwatch',
        email: '',
        password: '',
        battle_tag: '',
        price: 0,
        dps_rank: '',
        tank_rank: '',
        support_rank: '',
        ow1_coins: 0,
        paid_currency_coins: 0,
        golden_gun_coins: 0,
        overwatch_league_tokens: 0,
        golden_guns: 0,
        battle_pass_level: 0,
        rare_skins: 0,
        platform: 1, // Por defecto a PC
        galery: '',
        status: 1
    });
    
    const [editData, setEditData] = useState(null);
    const [filterEstados, setFilterEstados] = useState({
        oculto: false,
        publicado: false,
        enCarrito: false,
        comprado: false,
        entregado: false
    });    
    const [ventas, setVentas] = useState([]);
    const [showPassword, setShowPassword] = useState(false);
    const [showEditPassword, setShowEditPassword] = useState(false);
    const [platformOptions, setPlatformOptions] = useState([
        { id: 1, label: 'PC' },
        { id: 2, label: 'Consola' },
    ]);
    const estados = {
        1: "Oculto",
        2: "Publicado",
        3: "En Carrito",
        4: "Comprado",
        5: "Entregado"
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = ventas.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const handleNextPage = () => {
        if (currentPage < Math.ceil(ventas.length / itemsPerPage)) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };



    const handleEditChange = (e) => {
        const { name, value } = e.target;
        setEditData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };
    
    const handleFilterEstadoChange = (e) => {
        const { name, checked } = e.target;
        setFilterEstados((prevFilters) => ({
            ...prevFilters,
            [name]: checked,
        }));
    };
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log('Formulario enviado:', formData);
    
        // Validación básica
        if (!formData.email || !formData.password || !formData.battle_tag) {
            alert('Por favor, completa todos los campos obligatorios.');
            return;
        }
    
        if (formData.price <= 0) {
            alert('El precio debe ser un número positivo.');
            return;
        }
    
        try {
            const response = await fetch('https://alienboost.onrender.com/crear-venta', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    title: formData.title,
                    image: formData.image, // Campo de imagen como texto
                    email: formData.email,
                    password: formData.password,
                    battle_tag: formData.battle_tag,
                    price: parseFloat(formData.price), // Asegúrate de convertir a float
                    dps_rank: formData.dps_rank,
                    tank_rank: formData.tank_rank,
                    support_rank: formData.support_rank,
                    ow1_coins: parseInt(formData.ow1_coins, 10) || 0,
                    paid_currency_coins: parseInt(formData.paid_currency_coins, 10) || 0,
                    golden_gun_coins: parseInt(formData.golden_gun_coins, 10) || 0,
                    overwatch_league_tokens: parseInt(formData.overwatch_league_tokens, 10) || 0,
                    golden_guns: parseInt(formData.golden_guns, 10) || 0,
                    battle_pass_level: parseInt(formData.battle_pass_level, 10) || 0,
                    rare_skins: parseInt(formData.rare_skins, 10) || 0,
                    platform: parseInt(formData.platform, 10),
                    level: parseInt(formData.level, 10) || 0,
                    ranked_ready: formData.ranked_ready,
                    account_type: formData.account_type,
                    galery: formData.galery,
                    battle_tag_change: formData.battle_tag_change,
                    fake_id: formData.fake_id
                }),
            });
    
            if (!response.ok) {
                const errorData = await response.json();
                console.error('Error al crear la venta:', errorData);
                alert(`Error: ${errorData.detail || 'Error al crear la venta'}`);
                return;
            }
    
            const data = await response.json();
            console.log('Venta creada:', data);
            
            setModalIsOpen(false);
            // Reiniciar el formulario
            setFormData({
                title: '',
                image: '', // Reinicia el campo de imagen
                game: 'Overwatch',
                email: '',
                password: '',
                battle_tag: '',
                price: 0,
                dps_rank: '',
                tank_rank: '',
                support_rank: '',
                ow1_coins: 0,
                paid_currency_coins: 0,
                golden_gun_coins: 0,
                overwatch_league_tokens: 0,
                golden_guns: 0,
                battle_pass_level: 0,
                rare_skins: 0,
                platform: 1,
                level: 1,
                ranked_ready: '',
                account_type: '',
                galery: '',
                battle_tag_change: '',
                fake_id: '',
                status: 1,

            });
            fetchVentas(); // Obtener la lista de ventas nuevamente
    
        } catch (error) {
            console.error('Error:', error);
            alert('Ocurrió un error al crear la venta. Intenta nuevamente.');
        }
    };
    
    const handleEditSubmit = async (event) => {
        event.preventDefault();
    
        try {
            const response = await fetch(`https://alienboost.onrender.com/editar-venta/${editData.id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(editData),
            });
    
            if (!response.ok) {
                throw new Error('Error al actualizar la venta');
            }
    
            const result = await response.json();
            alert(result.message);
    
            // Actualiza la venta en el estado local
            setVentas((prevVentas) =>
                prevVentas.map((venta) => (venta.id === editData.id ? editData : venta))
            );
    
            // Cerrar el modal
            setEditModalIsOpen(false);
        } catch (error) {
            console.error('Error:', error);
            alert('Ocurrió un error al actualizar la venta');
        }
    };   
    
    const handleEditClick = (venta) => {
        setEditData(venta);
        setEditModalIsOpen(true);
    };

    const handleDelete = async (id) => {
        const confirmDelete = window.confirm('¿Está seguro que quiere eliminar esta cuenta?');
        if (confirmDelete) {
            try {
                const response = await fetch(`https://alienboost.onrender.com/borrar-venta/${id}`, {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
    
                if (!response.ok) {
                    const errorData = await response.json();
                    console.error('Error al eliminar la venta:', errorData.detail);
                    throw new Error('Error al eliminar la venta: ' + errorData.detail);
                }
    
                console.log('Venta eliminada con éxito');
                fetchVentas(); // Refresh the list of ventas after deletion
            } catch (error) {
                console.error('Error:', error);
            }
        }
    };

    const fetchVentas = async () => {
        try {
            const response = await fetch('https://alienboost.onrender.com/ventas');
            if (!response.ok) {
                throw new Error('Error al obtener las ventas');
            }
            const data = await response.json();
            setVentas(data);
        } catch (error) {
            console.error('Error:', error);
        }
    };

// Llama a fetchVentas al montar el componente
        useEffect(() => {
            fetchVentas();
        }, []);

        useEffect(() => {
            setCurrentPage(1); // Reiniciar a la primera página cuando cambien las ventas
        }, [ventas]);
        
    

    return (
        <>
            <Navbar />
            <div className="ventaow-container">
                <h2 className="ventaow-title">Agregar Cuenta</h2>
                <button 
                    onClick={() => { setModalIsOpen(true); console.log('Modal abierto'); }} 
                    className="open-modal-button"
                >
                    Agregar Cuenta
                </button>

                                                            <Modal 
                                                            isOpen={modalIsOpen} 
                                                            onRequestClose={() => setModalIsOpen(false)} 
                                                            contentLabel="Agregar Cuenta"
                                                            className="modal"
                                                            overlayClassName="overlay"
                                                        >
                                                            <h2 className="modal-title">Formulario de Venta</h2>
                                                            <form onSubmit={handleSubmit} className="ventaow-form">
                                                            <label htmlFor="title" className="ventaow-label">Title</label>
                                                                <input
                                                                    type="text"
                                                                    id="title"
                                                                    name="title"
                                                                    className="ventaow-input"
                                                                    value={formData.title}
                                                                    onChange={handleChange}
                                                                    required
                                                                />
                                                            <label htmlFor="image" className="ventaow-label">Image</label>
                                                                <input
                                                                    type="text"
                                                                    id="image"
                                                                    name="image"
                                                                    className="ventaow-input"
                                                                    value={formData.image}
                                                                    onChange={handleChange}
                                                                    required
                                                                />
                                                                <label htmlFor="email" className="ventaow-label">Correo Electrónico</label>
                                                                <input
                                                                    type="email"
                                                                    id="email"
                                                                    name="email"
                                                                    className="ventaow-input"
                                                                    value={formData.email}
                                                                    onChange={handleChange}
                                                                    required
                                                                />
                                                                
                                                                <label htmlFor="password" className="ventaow-label">Contraseña</label>
                                                                <div className="password-container">
                                                                    <input
                                                                        type={showPassword ? "text" : "password"}
                                                                        id="password"
                                                                        name="password"
                                                                        className="ventaow-input"
                                                                        value={formData.password}
                                                                        onChange={handleChange}
                                                                        required
                                                                    />
                                                                    <button type="button" onClick={() => setShowPassword(!showPassword)}>
                                                                        👁️
                                                                    </button>
                                                                </div>

                                                                <label htmlFor="battle_tag" className="ventaow-label">Battle Tag</label>
                                                                <input
                                                                    type="text"
                                                                    id="battle_tag"
                                                                    name="battle_tag"
                                                                    className="ventaow-input"
                                                                    value={formData.battle_tag}
                                                                    onChange={handleChange}
                                                                    required
                                                                />

                                                                <label htmlFor="price" className="ventaow-label">Precio</label>
                                                                <input
                                                                    type="number"
                                                                    id="price"
                                                                    name="price"
                                                                    className="ventaow-input"
                                                                    value={formData.price}
                                                                    onChange={handleChange}
                                                                    required
                                                                />

                                                                <label htmlFor="dps_rank" className="ventaow-label">DPS Rank</label>
                                                                <input
                                                                    type="text"
                                                                    id="dps_rank"
                                                                    name="dps_rank"
                                                                    className="ventaow-input"
                                                                    value={formData.dps_rank}
                                                                    onChange={handleChange}
                                                                    required
                                                                />

                                                                <label htmlFor="tank_rank" className="ventaow-label">Tank Rank</label>
                                                                <input
                                                                    type="text"
                                                                    id="tank_rank"
                                                                    name="tank_rank"
                                                                    className="ventaow-input"
                                                                    value={formData.tank_rank}
                                                                    onChange={handleChange}
                                                                    required
                                                                />

                                                                <label htmlFor="support_rank" className="ventaow-label">Support Rank</label>
                                                                <input
                                                                    type="text"
                                                                    id="support_rank"
                                                                    name="support_rank"
                                                                    className="ventaow-input"
                                                                    value={formData.support_rank}
                                                                    onChange={handleChange}
                                                                    required
                                                                />

                                                                <label htmlFor="ow1_coins" className="ventaow-label">OW1 Coins</label>
                                                                <input
                                                                    type="number"
                                                                    id="ow1_coins"
                                                                    name="ow1_coins"
                                                                    className="ventaow-input"
                                                                    value={formData.ow1_coins}
                                                                    onChange={handleChange}
                                                                    required
                                                                />

                                                                <label htmlFor="paid_currency_coins" className="ventaow-label">Paid Currency Coins</label>
                                                                <input
                                                                    type="number"
                                                                    id="paid_currency_coins"
                                                                    name="paid_currency_coins"
                                                                    className="ventaow-input"
                                                                    value={formData.paid_currency_coins}
                                                                    onChange={handleChange}
                                                                    required
                                                                />

                                                                <label htmlFor="golden_gun_coins" className="ventaow-label">Golden Gun Coins</label>
                                                                <input
                                                                    type="number"
                                                                    id="golden_gun_coins"
                                                                    name="golden_gun_coins"
                                                                    className="ventaow-input"
                                                                    value={formData.golden_gun_coins}
                                                                    onChange={handleChange}
                                                                    required
                                                                />

                                                                <label htmlFor="overwatch_league_tokens" className="ventaow-label">Overwatch League Tokens</label>
                                                                <input
                                                                    type="number"
                                                                    id="overwatch_league_tokens"
                                                                    name="overwatch_league_tokens"
                                                                    className="ventaow-input"
                                                                    value={formData.overwatch_league_tokens}
                                                                    onChange={handleChange}
                                                                    required
                                                                />

                                                                <label htmlFor="golden_guns" className="ventaow-label">Golden Guns</label>
                                                                <input
                                                                    type="number"
                                                                    id="golden_guns"
                                                                    name="golden_guns"
                                                                    className="ventaow-input"
                                                                    value={formData.golden_guns}
                                                                    onChange={handleChange}
                                                                    required
                                                                />

                                                                <label htmlFor="battle_pass_level" className="ventaow-label">Battle Pass Level</label>
                                                                <input
                                                                    type="number"
                                                                    id="battle_pass_level"
                                                                    name="battle_pass_level"
                                                                    className="ventaow-input"
                                                                    value={formData.battle_pass_level}
                                                                    onChange={handleChange}
                                                                    required
                                                                />

                                                                <label htmlFor="rare_skins" className="ventaow-label">Rare Skins</label>
                                                                <input
                                                                    type="number"
                                                                    id="rare_skins"
                                                                    name="rare_skins"
                                                                    className="ventaow-input"
                                                                    value={formData.rare_skins}
                                                                    onChange={handleChange}
                                                                    required
                                                                />
                                                                <label htmlFor="galery" className="ventaow-label">Galería</label>
                                                                    <input
                                                                        type="text"
                                                                        id="galery"
                                                                        name="galery"
                                                                        className="ventaow-input"
                                                                        value={formData.galery}
                                                                        onChange={handleChange}
                                                                        required // Set this if it's a required field
                                                                    />

                                                                <label htmlFor="platform" className="ventaow-label">Plataforma</label>
                                                                <select
                                                                    id="platform"
                                                                    name="platform"
                                                                    className="ventaow-input"
                                                                    value={formData.platform}
                                                                    onChange={handleChange}
                                                                    required
                                                                >
                                                                    {platformOptions.map((option) => (
                                                                        <option key={option.id} value={option.id}>
                                                                            {option.label}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                                <label htmlFor="level" className="ventaow-label">Level</label>
                                                                <input
                                                                    type="number"
                                                                    id="level"
                                                                    name="level"
                                                                    className="ventaow-input"
                                                                    value={formData.level}
                                                                    onChange={handleChange}
                                                                />
                                                                <label htmlFor="ranked_ready" className="ventaow-label">Ranked Ready</label>
                                                                    <input
                                                                        type="text"
                                                                        id="ranked_ready"
                                                                        name="ranked_ready"
                                                                        className="ventaow-input"
                                                                        value={formData.ranked_ready}
                                                                        onChange={handleChange}
                                                                        required 
                                                                />
                                                                 <label htmlFor="account_type" className="ventaow-label">Account Type</label>
                                                                    <input
                                                                        type="text"
                                                                        id="account_type"
                                                                        name="account_type"
                                                                        className="ventaow-input"
                                                                        value={formData.account_type}
                                                                        onChange={handleChange}
                                                                        required 
                                                                />
                                                                 <label htmlFor="battle_tag_change" className="ventaow-label">Battle tag change</label>
                                                                    <input
                                                                        type="text"
                                                                        id="battle_tag_change"
                                                                        name="battle_tag_change"
                                                                        className="ventaow-input"
                                                                        value={formData.battle_tag_change}
                                                                        onChange={handleChange}
                                                                        required 
                                                                />
                                                                <label htmlFor="fake_id" className="ventaow-label">Fake ID</label>
                                                                    <input
                                                                        type="text"
                                                                        id="fake_id"
                                                                        name="fake_id"
                                                                        className="ventaow-input"
                                                                        value={formData.fake_id}
                                                                        onChange={handleChange}
                                                                        required 
                                                                />

                                                                <button type="submit" className="ventaow-button">Crear Venta</button>
                                                            </form>
                                                            <button onClick={() => setModalIsOpen(false)} className="close-modal-button">Cerrar</button>
                                                        </Modal>

                <h2 className="ventas-title">Cuentas Registradas</h2>
                <div className="filter-container">
    <label>
        <input
            type="checkbox"
            name="oculto"
            checked={filterEstados.oculto}
            onChange={handleFilterEstadoChange}
        />
        Oculto
    </label>
    <label>
        <input
            type="checkbox"
            name="publicado"
            checked={filterEstados.publicado}
            onChange={handleFilterEstadoChange}
        />
        Publicado
    </label>
    <label>
        <input
            type="checkbox"
            name="enCarrito"
            checked={filterEstados.enCarrito}
            onChange={handleFilterEstadoChange}
        />
        En Carrito
    </label>
    <label>
        <input
            type="checkbox"
            name="comprado"
            checked={filterEstados.comprado}
            onChange={handleFilterEstadoChange}
        />
        Comprado
    </label>
    <label>
        <input
            type="checkbox"
            name="entregado"
            checked={filterEstados.entregado}
            onChange={handleFilterEstadoChange}
        />
        Entregado
    </label>
</div>

                <table className="ventas-table">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Game</th>
                            <th>Email</th>
                            <th>Battletag</th>
                            <th>Price</th>
                            <th>DPS Rank</th>
                            <th>Tank Rank</th>
                            <th>Support Rank</th>   
                            <th>Status</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentItems
                            .filter((venta) => {
                                // Si no hay checkboxes seleccionados, mostrar todas las ventas
                                const selectedEstados = Object.entries(filterEstados)
                                    .filter(([key, value]) => value)
                                    .map(([key]) => key);

                                if (selectedEstados.length === 0) return true;

                                // Filtrar según los estados seleccionados
                                const estadoMap = {
                                    oculto: 1,
                                    publicado: 2,
                                    enCarrito: 3,
                                    comprado: 4,
                                    entregado: 5,
                                };

                                return selectedEstados.includes(
                                    Object.keys(estadoMap).find((key) => estadoMap[key] === venta.status)
                                );
                            })
                            .map((venta) => (
                                <tr key={venta.id}>
                                    <td data-label="ID">{venta.id}</td>
                                    <td data-label="Game">{venta.game}</td>
                                    <td data-label="Email">{venta.email}</td>
                                    <td data-label="Battletag">{venta.battle_tag}</td>
                                    <td data-label="Price">{venta.price}</td>
                                    <td data-label="DPS Rank">{venta.dps_rank}</td>
                                    <td data-label="Tank Rank">{venta.tank_rank}</td>
                                    <td data-label="Support Rank">{venta.support_rank}</td>
                                    <td data-label="Status">{estados[venta.status]}</td>
                                    <td data-label="Actions">
                                        <button onClick={() => handleEditClick(venta)}>✏️</button>
                                        <button onClick={() => handleDelete(venta.id)}>🗑️</button>
                                    </td>
                                </tr>
                            ))}
                    </tbody>



                </table>
                <div className="pagination-container">
                    <button
                        onClick={handlePreviousPage}
                        disabled={currentPage === 1}
                        className="pagination-button"
                    >
                        &lt; {/* Flecha izquierda */}
                    </button>
                    <span className="pagination-info">Página {currentPage}</span>
                    <button
                        onClick={handleNextPage}
                        disabled={currentPage === Math.ceil(ventas.length / itemsPerPage)}
                        className="pagination-button"
                    >
                        &gt; {/* Flecha derecha */}
                    </button>
                </div>


                {/* Modal para editar datos */}
                <Modal  
                    isOpen={editModalIsOpen} 
                    onRequestClose={() => setEditModalIsOpen(false)} 
                    contentLabel="Editar Cuenta"
                    className="modal"
                    overlayClassName="overlay"
                >
                    <h2 className="modal-title">Editar Cuenta</h2>
                    <form onSubmit={handleEditSubmit} className="ventaow-form">

                        <label htmlFor="edit-title" className="ventaow-label">Title</label>
                        <input
                            type="text"
                            id="edit-title"
                            name="title"
                            className="ventaow-input"
                            value={editData?.title || ''}
                            onChange={handleEditChange}
                            required
                        />
                        <label htmlFor="edit-email" className="ventaow-label">Email</label>
                        <input
                            type="email"
                            id="edit-email"
                            name="email"
                            className="ventaow-input"
                            value={editData?.email || ''}
                            onChange={handleEditChange}
                            required
                        />

                        <label htmlFor="edit-password" className="ventaow-label">Contraseña</label>
                        <div className="password-container">
                            <input
                                type={showEditPassword ? "text" : "password"}
                                id="edit-password"
                                name="password"
                                className="ventaow-input"
                                value={editData?.password || ''}
                                onChange={handleEditChange}
                                required
                            />
                            <button type="button" onClick={() => setShowEditPassword(!showEditPassword)}>
                                👁️
                            </button>
                        </div>

                        <label htmlFor="edit-battle-tag" className="ventaow-label">Battle Tag</label>
                        <input
                            type="text"
                            id="edit-battle-tag"
                            name="battle_tag"
                            className="ventaow-input"
                            value={editData?.battle_tag || ''}
                            onChange={handleEditChange}
                            required
                        />

                        <label htmlFor="edit-price" className="ventaow-label">Precio</label>
                        <input
                            type="number"
                            id="edit-price"
                            name="price"
                            className="ventaow-input"
                            value={editData?.price || ''}
                            onChange={handleEditChange}
                            required
                        />

                        <label htmlFor="edit-dps-rank" className="ventaow-label">DPS Rank</label>
                        <input
                            type="text"
                            id="edit-dps-rank"
                            name="dps_rank"
                            className="ventaow-input"
                            value={editData?.dps_rank || ''}
                            onChange={handleEditChange}
                            required
                        />

                        <label htmlFor="edit-tank-rank" className="ventaow-label">Tank Rank</label>
                        <input
                            type="text"
                            id="edit-tank-rank"
                            name="tank_rank"
                            className="ventaow-input"
                            value={editData?.tank_rank || ''}
                            onChange={handleEditChange}
                            required
                        />

                        <label htmlFor="edit-support-rank" className="ventaow-label">Support Rank</label>
                        <input
                            type="text"
                            id="edit-support-rank"
                            name="support_rank"
                            className="ventaow-input"
                            value={editData?.support_rank || ''}
                            onChange={handleEditChange}
                            required
                        />

                        <label htmlFor="edit-ow1-coins" className="ventaow-label">OW1 Coins</label>
                        <input
                            type="number"
                            id="edit-ow1-coins"
                            name="ow1_coins"
                            className="ventaow-input"
                            value={editData?.ow1_coins || ''}
                            onChange={handleEditChange}
                            required
                        />

                        <label htmlFor="edit-paid-currency-coins" className="ventaow-label">Paid Currency Coins</label>
                        <input
                            type="number"
                            id="edit-paid-currency-coins"
                            name="paid_currency_coins"
                            className="ventaow-input"
                            value={editData?.paid_currency_coins || ''}
                            onChange={handleEditChange}
                            required
                        />

                        <label htmlFor="edit-golden-gun-coins" className="ventaow-label">Golden Gun Coins</label>
                        <input
                            type="number"
                            id="edit-golden-gun-coins"
                            name="golden_gun_coins"
                            className="ventaow-input"
                            value={editData?.golden_gun_coins || ''}
                            onChange={handleEditChange}
                            required
                        />

                        <label htmlFor="edit-overwatch-league-tokens" className="ventaow-label">Overwatch League Tokens</label>
                        <input
                            type="number"
                            id="edit-overwatch-league-tokens"
                            name="overwatch_league_tokens"
                            className="ventaow-input"
                            value={editData?.overwatch_league_tokens || ''}
                            onChange={handleEditChange}
                            required
                        />

                        <label htmlFor="edit-golden-guns" className="ventaow-label">Golden Guns</label>
                        <input
                            type="number"
                            id="edit-golden-guns"
                            name="golden_guns"
                            className="ventaow-input"
                            value={editData?.golden_guns || ''}
                            onChange={handleEditChange}
                            required
                        />

                        <label htmlFor="edit-battle-pass-level" className="ventaow-label">Battle Pass Level</label>
                        <input
                            type="number"
                            id="edit-battle-pass-level"
                            name="battle_pass_level"
                            className="ventaow-input"
                            value={editData?.battle_pass_level || ''}
                            onChange={handleEditChange}
                            required
                        />

                        <label htmlFor="edit-rare-skins" className="ventaow-label">Rare Skins</label>
                        <input
                            type="number"
                            id="edit-rare-skins"
                            name="rare_skins"
                            className="ventaow-input"
                            value={editData?.rare_skins || ''}
                            onChange={handleEditChange}
                            required
                        />

                        <label htmlFor="edit-platform" className="ventaow-label">Platform</label>
                        <select
                            id="edit-platform"
                            name="platform"
                            className="ventaow-input"
                            value={editData?.platform || ''}
                            onChange={handleEditChange}
                            required
                        >
                            <option value="" disabled>Selecciona una opción</option>
                            {platformOptions.map(option => (
                                <option key={option.id} value={option.id}>{option.label}</option>
                            ))}
                        </select>

                        <label htmlFor="edit-gallery" className="ventaow-label">Gallery</label>
                        <textarea
                            id="edit-gallery"
                            name="galery"
                            className="ventaow-input"
                            value={editData?.galery || ''}
                            onChange={handleEditChange}
                            required
                        ></textarea>

                        <label htmlFor="edit-level" className="ventaow-label">Level</label>
                        <input
                            type="number"
                            id="edit-level"
                            name="level"
                            className="ventaow-input"
                            value={editData?.level || ''}
                            onChange={handleEditChange}
                            required
                        />
                        <label htmlFor="edit-ranked_ready" className="ventaow-label">Ranked ready</label>
                        <input
                            type="text"
                            id="edit-ranked_ready"
                            name="ranked_ready"
                            className="ventaow-input"
                            value={editData?.ranked_ready || ''}
                            onChange={handleEditChange}
                            required
                        />
                        <label htmlFor="edit-account_type" className="ventaow-label">Account type</label>
                        <input
                            type="text"
                            id="edit-account_type"
                            name="account_type"
                            className="ventaow-input"
                            value={editData?.account_type || ''}
                            onChange={handleEditChange}
                            required
                        />
                        <label htmlFor="edit-battle_tag_change" className="ventaow-label">Battle tag change</label>
                        <input
                            type="text"
                            id="edit-battle_tag_change"
                            name="battle_tag_change"
                            className="ventaow-input"
                            value={editData?.battle_tag_change || ''}
                            onChange={handleEditChange}
                            required
                        />
                        <label htmlFor="edit-fake_id" className="ventaow-label">Fake ID</label>
                        <input
                            type="text"
                            id="edit-fake_id"
                            name="fake_id"
                            className="ventaow-input"
                            value={editData?.fake_id || ''}
                            onChange={handleEditChange}
                            required
                        />
                        <label htmlFor="edit-status" className="ventaow-label">Status</label>
                        <select
                            id="edit-status"
                            name="status"
                            className="ventaow-input"
                            value={editData?.status || ''}
                            onChange={handleEditChange}
                            required
                        >
                            <option value="" disabled>Selecciona una opción</option>
                            {Object.entries(estados).map(([key, value]) => (
                                <option key={key} value={key}>{value}</option>
                            ))}
                        </select>

                        <button type="submit" className="ventaow-button">Guardar Cambios</button>
                    </form>
                    <button onClick={() => setEditModalIsOpen(false)} className="close-modal-button">Cerrar</button>
                </Modal>



            </div>
        </>
    );
}

export default VentaOW;
