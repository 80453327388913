/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Footer from './footer';
import Navbar from './navbar';
import '../styles/PaymentSelection.css';
import BinanceLogo from '../img/Binance.png';

const PaymentSelection = () => {
    const navigate = useNavigate();
    const userId = localStorage.getItem('userId');
    const [totalPrice, setTotalPrice] = useState(0);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [sdkLoaded, setSdkLoaded] = useState(false); // Nuevo estado para rastrear la carga del SDK

    // Función para cargar el SDK de PayPal
    const loadPayPalScript = () => {
        if (document.getElementById('paypal-sdk')) {
            console.log('El SDK de PayPal ya está cargado.');
            setSdkLoaded(true);
            return;
        }

        const script = document.createElement('script');
        script.src = 'https://www.paypal.com/sdk/js?client-id=ATH56EBmRwGSgnAfuaXLC3m8wRFlqVYYk-IoWAWPwJSaBMPWqF4Ywabs0CW_4DxIBn-RwS_wROiYCATY';
        script.id = 'paypal-sdk';
        script.async = true;

        script.onload = () => {
            console.log('SDK de PayPal cargado.');
            setSdkLoaded(true);
        };

        script.onerror = () => {
            console.error('No se pudo cargar el SDK de PayPal. Reintentando en 3 segundos.');
            setTimeout(loadPayPalScript, 3000); // Reintentar cargar después de 3 segundos
        };

        document.body.appendChild(script);
    };

    // Función para renderizar los botones de PayPal
    const renderPayPalButtons = () => {
        const container = document.getElementById('paypal-button-container');
        if (!window.paypal || !container) {
            console.error('El SDK de PayPal no está disponible o el contenedor no se encuentra.');
            return;
        }

        container.innerHTML = ''; // Limpia el contenedor antes de renderizar

        window.paypal.Buttons({
            createOrder: async () => {
                try {
                    const orderPayload = {
                        total: parseFloat(totalPrice).toFixed(2),
                        user_id: parseInt(userId),
                    };

                    console.log('Datos enviados al backend:', orderPayload);

                    const response = await fetch('https://alienboost.onrender.com/create-order', {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify(orderPayload),
                    });

                    if (!response.ok) {
                        const errorData = await response.json();
                        console.error('Error al crear la orden:', errorData);
                        throw new Error('No se pudo crear la orden.');
                    }

                    const { id: orderId } = await response.json();
                    console.log('Orden creada con éxito. ID:', orderId);
                    return orderId;
                } catch (error) {
                    console.error('Error al crear la orden:', error);
                    alert('Hubo un problema al crear la orden. Intenta de nuevo.');
                    throw error;
                }
            },
            onApprove: async (data) => {
                try {
                    console.log('Orden aprobada con ID:', data.orderID);

                    console.log('Datos enviados al backend:', {
                        order_id: data.orderID,
                        user_id: parseInt(userId),
                    });

                    const updateResponse = await fetch('https://alienboost.onrender.com/complete-payment', {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({
                            order_id: data.orderID,
                            user_id: parseInt(userId),
                        }),
                    });

                    if (!updateResponse.ok) {
                        const updateError = await updateResponse.json();
                        console.error('Error al actualizar los datos después del pago:', updateError);
                        throw new Error('No se pudieron actualizar los datos después del pago.');
                    }

                    const updateData = await updateResponse.json();
                    console.log('Datos actualizados con éxito:', updateData);

                    alert('Pago completado y datos actualizados con éxito.');
                    navigate('/ordenes'); // Redirige al usuario a las órdenes
                } catch (error) {
                    console.error('Error al completar el pago:', error);
                    alert('Hubo un problema al procesar el pago.');
                }
            },
        }).render(container);
    };

    // Efecto para obtener carritos
    useEffect(() => {
        const fetchCarritos = async () => {
            try {
                if (!userId) {
                    setError('Usuario no encontrado en el localStorage');
                    return;
                }

                console.log('Obteniendo carritos para userId:', userId);
                const response = await fetch(`https://alienboost.onrender.com/existe-carrito/${userId}`);
                if (!response.ok) throw new Error('Error al obtener los carritos');

                const data = await response.json();
                const activeCarritos = data.filter((carrito) => carrito.carrito_estado === 'activo');
                console.log('Carritos activos:', activeCarritos);

                const total = activeCarritos.reduce((sum, carrito) => sum + parseFloat(carrito.carrito_precio), 0);
                console.log('Total calculado antes de redondear:', total);

                setTotalPrice(total.toFixed(2));
            } catch (error) {
                console.error('Error al obtener los carritos:', error);
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchCarritos();
    }, [userId]);

    // Efecto para cargar el SDK de PayPal
    useEffect(() => {
        if (totalPrice > 0) {
            loadPayPalScript();
        }
    }, [totalPrice]);

    // Efecto para renderizar los botones cuando el SDK esté cargado
    useEffect(() => {
        if (sdkLoaded && totalPrice > 0) {
            renderPayPalButtons();
        }
    }, [sdkLoaded, totalPrice]);

    return (
        <div className="main-container">
            <Navbar />
            <div className="payment-container">
                <h1 className="payment-title">Choose Your Payment Method</h1>
                {loading ? (
                    <p className="payment-loading">Loading total price...</p>
                ) : error ? (
                    <p className="payment-error">{error}</p>
                ) : (
                    <p className="payment-total">Total Price: ${totalPrice}</p>
                )}
                <div id="paypal-button-container"></div>
                <div className="payment-buttons">
                    <button
                        className="payment-button payment-binance"
                        onClick={() => window.open('https://www.binance.com/en/binance-pay', '_blank')}
                    >
                        <img src={BinanceLogo} alt="Binance" className="payment-logo" />
                        Pay with Binance
                    </button>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default PaymentSelection;
