/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Footer from "./footer";
import Navbar from "./navbar";
import "../styles/ordenes.css";

const userId = localStorage.getItem("userId");

const Ordenes = () => {
  const [carritos, setCarritos] = useState([]);
  const [filteredCarritos, setFilteredCarritos] = useState([]);
  const [activeTab, setActiveTab] = useState("paid");
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [venta, setVenta] = useState(null);
  const [boostDescripcionDetallada, setBoostDescripcionDetallada] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1); // Página actual
  const itemsPerPage = 4; // Elementos por página
  // Calcular los índices de inicio y fin para la paginación
const indexOfLastItem = currentPage * itemsPerPage;
const indexOfFirstItem = indexOfLastItem - itemsPerPage;
const currentItems = filteredCarritos.slice(indexOfFirstItem, indexOfLastItem);

// Función para cambiar de página
const paginate = (direction) => {
  setCurrentPage((prevPage) => {
    if (direction === "next") {
      return prevPage + 1 > Math.ceil(filteredCarritos.length / itemsPerPage)
        ? prevPage
        : prevPage + 1;
    } else if (direction === "prev") {
      return prevPage - 1 < 1 ? prevPage : prevPage - 1;
    }
  });
};



useEffect(() => {
    const fetchCarritos = async () => {
        try {
            if (userId) {
                const response = await fetch(`https://alienboost.onrender.com/existe-carrito/${userId}`);
                if (!response.ok) {
                    throw new Error('Error al obtener los carritos');
                }
                const data = await response.json();

                console.log("Data recibida:", data); // Aquí se imprime la data que recibes
                
                setCarritos(data); // Guardamos todos los carritos en el estado
                
                // Filtrar automáticamente los carritos en estado "Paid" al cargar
                const estadoMap = {
                    paid: "pagado",
                };
                const filtered = data.filter(
                    (carrito) => carrito.carrito_estado === estadoMap.paid
                );
                setFilteredCarritos(filtered); // Establecer los carritos filtrados
            } else {
                setError('Usuario no encontrado en el localStorage');
            }
        } catch (error) {
            setError(error.message); // Guardamos cualquier error
        } finally {
            setLoading(false); // Cambiamos el estado de loading a false
        }
    };

    fetchCarritos();
}, [userId]); // Dependemos de userId, se ejecutará cada vez que cambie.


const filterCarritos = (tab) => {
    const estadoMap = {
      paid: "pagado",
      inProgress: "enProgreso",
      completed: "completado",
    };
  
    const estado = estadoMap[tab];
  
    // Verificar que carritos sea un arreglo
    if (!Array.isArray(carritos)) {
      console.error("carritos is not an array:", carritos);
      return;
    }
  
    const filtered = carritos.filter(
      (carrito) => carrito.carrito_estado === estado
    );
  
    setFilteredCarritos(filtered); // Actualizar carritos filtrados
    setActiveTab(tab);            // Cambiar la pestaña activa
  };
  

  const handleVerDetalles = (id, tipo) => {
    setModalContent({ id, tipo });
    setModalOpen(true);

    if (tipo === "Venta") {
      fetchVenta(id);
    } else if (tipo === "Boost") {
      const carrito = carritos.find((carrito) => carrito.boost_id === id);
      if (carrito) {
        setBoostDescripcionDetallada(carrito.boost_descripcion_detallada);
      }
    }
  };

  const fetchVenta = async (id) => {
    try {
      const response = await fetch(
        `https://alienboost.onrender.com/ventas/${id}`
      );
      const data = await response.json();
      setVenta(data);
    } catch (error) {
      console.error("Error fetching venta details:", error);
    }
  };

  const closeModal = () => {
    setModalOpen(false);
    setModalContent(null);
  };

  return (
    <div className="main-container">
      <Navbar />
      <div className="contenedor-ordenes">
        <h1 className="titulo-orden">My Orders</h1>


        <div className="tabs-orden">
          <button
            className={`tab-orden ${activeTab === "paid" ? "active-orden" : ""}`}
            onClick={() => filterCarritos("paid")}
          >
            Paid
          </button>
          <button
            className={`tab-orden ${
              activeTab === "inProgress" ? "active-orden" : ""
            }`}
            onClick={() => filterCarritos("inProgress")}
          >
            In Progress
          </button>
          <button
            className={`tab-orden ${
              activeTab === "completed" ? "active-orden" : ""
            }`}
            onClick={() => filterCarritos("completed")}
          >
            Completed
          </button>
        </div>
        <table className="tabla-orden">
          <thead>
            <tr>
              <th>ID</th>
              <th>Game</th>
              <th>Description</th>
              <th>Price</th>
              <th>Type</th>
              <th>Details</th>
            </tr>
          </thead>
          <tbody>
  {currentItems.map((carrito) => (
    <tr key={carrito.carrito_id}>
      <td data-label="ID">{carrito.venta_id || carrito.boost_id}</td>
      <td data-label="Game">{carrito.boost_juego || carrito.venta_juego}</td>
      <td data-label="Description">{carrito.venta_titulo || carrito.boost_descripcion}</td>
      <td data-label="Price">${carrito.carrito_precio.toFixed(2)}</td>
      <td data-label="Type">
                  {carrito.venta_id ? "Account" : "Boost"}
                </td>
      <td data-label="Details">
        <button
          className="detalles-btn-orden"
          onClick={() =>
            handleVerDetalles(
              carrito.venta_id || carrito.boost_id,
              carrito.venta_id ? "Venta" : "Boost"
            )
          }
        >
          View Details
        </button>
      </td>
    </tr>
  ))}
</tbody>


        </table>

        <div className="pagination-container">
  <button
    className="pagination-button"
    onClick={() => paginate("prev")}
    disabled={currentPage === 1}
  >
    &lt; Prev
  </button>
  <span className="pagination-info">
    Page {currentPage} of {Math.ceil(filteredCarritos.length / itemsPerPage)}
  </span>
  <button
    className="pagination-button"
    onClick={() => paginate("next")}
    disabled={currentPage === Math.ceil(filteredCarritos.length / itemsPerPage)}
  >
    Next &gt;
  </button>
</div>

      </div>
      {/* Modal */}
      {modalOpen && (
        <div className="modal-overlay-orden">
          <div className="modal-content-orden">
            {modalContent?.tipo === "Venta" && venta && (
              <div>
                <h3 className="modal-title-orden">Account Specifications</h3>
                <div className="modal-section-orden">
                  <p>
                  <div className="detalle-orden-table-container">
    <div className="detalle-orden-table-section">
        <table className="detalle-orden-table">
            <tbody>
                <tr>
                    <td className="detalle-orden-title">Battle Tag</td>
                    <td className="detalle-orden-value">{venta.battle_tag}</td>
                </tr>
                <tr>
                    <td className="detalle-orden-title">DPS Rank</td>
                    <td className="detalle-orden-value">{venta.dps_rank}</td>
                </tr>
                <tr>
                    <td className="detalle-orden-title">Tank Rank</td>
                    <td className="detalle-orden-value">{venta.tank_rank}</td>
                </tr>
                <tr>
                    <td className="detalle-orden-title">Support Rank</td>
                    <td className="detalle-orden-value">{venta.support_rank}</td>
                </tr>
                <tr>
                    <td className="detalle-orden-title">OW1 Coins</td>
                    <td className="detalle-orden-value">{venta.ow1_coins}</td>
                </tr>
                <tr>
                    <td className="detalle-orden-title">Paid Currency Coins</td>
                    <td className="detalle-orden-value">{venta.paid_currency_coins}</td>
                </tr>
                <tr>
                    <td className="detalle-orden-title">Golden Gun Coins</td>
                    <td className="detalle-orden-value">{venta.golden_gun_coins}</td>
                </tr>
                <tr>
                    <td className="detalle-orden-title">OWL Tokens</td>
                    <td className="detalle-orden-value">{venta.overwatch_league_tokens}</td>
                </tr>
            </tbody>
        </table>
    </div>

    <div className="detalle-orden-table-section">
        <table className="detalle-orden-table">
            <tbody>
                <tr>
                    <td className="detalle-orden-title">Golden Guns</td>
                    <td className="detalle-orden-value">{venta.golden_guns}</td>
                </tr>
                <tr>
                    <td className="detalle-orden-title">Battle Pass Level</td>
                    <td className="detalle-orden-value">{venta.battle_pass_level}</td>
                </tr>
                <tr>
                    <td className="detalle-orden-title">Rare Skins</td>
                    <td className="detalle-orden-value">{venta.rare_skins}</td>
                </tr>
                <tr>
                    <td className="detalle-orden-title">Level</td>
                    <td className="detalle-orden-value">{venta.level}</td>
                </tr>
                <tr>
                    <td className="detalle-orden-title">Ranked Ready</td>
                    <td className="detalle-orden-value">{venta.ranked_ready}</td>
                </tr>
                <tr>
                    <td className="detalle-orden-title">Account Type</td>
                    <td className="detalle-orden-value">{venta.account_type}</td>
                </tr>
                <tr>
                    <td className="detalle-orden-title">Battle Tag Change</td>
                    <td className="detalle-orden-value">{venta.battle_tag_change}</td>
                </tr>
                <tr>
                    <td className="detalle-orden-title">Fake ID</td>
                    <td className="detalle-orden-value">{venta.fake_id}</td>
                </tr>
            </tbody>
        </table>
        
    </div>
    
</div>


                  </p>
                  {/* Add other details similarly */}
                </div>
                
              </div>
              
            )}
            {modalContent?.tipo === "Boost" && (
                <div className="boost-specifications-container">
                    <h3 className="modal-title-orden">Boost Specifications</h3>
                    <p>{boostDescripcionDetallada || "No additional details"}</p>
                </div>

            )}
            <button onClick={closeModal} className="close-modal-btn-orden">
              Close
            </button>
          </div>
        </div>
      )}
              <div className="info-mensajes">
          <p className="info-cuentas">For accounts: Check your messages to receive credentials</p>
          <p className="info-boost">For boosts: Contact us via messages to organize the boost</p>
        </div>
      <Footer />
    </div>
  );
};

export default Ordenes;
