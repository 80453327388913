/* eslint-disable react-hooks/exhaustive-deps */
// src/components/Home.js
import React, { useState, useEffect } from 'react';
import Navbar from './navbar'; // Importa el componente Navbar
import Footer from './footer'; // Importa el Footer
import '../styles/home.css';
import exampleImage from '../img/alien.png'; // Asegúrate de tener una imagen en esta ruta
import alien1 from '../img/alien1.png';
import alien2 from '../img/alien2.png';
import alien3 from '../img/alien3.png';
import logo2 from '../img/logo1.png'; // Reemplaza con tus logos
import logo1 from '../img/logo2.png'; // Reemplaza con tus logos
import logo3 from '../img/logo3.png'; // Reemplaza con tus logos
import logo4 from '../img/logo4.png'; // Reemplaza con tus logos
import logo5 from '../img/logo5.png'; // Reemplaza con tus logos
import usuario1 from '../img/usuario1.jpg';
import usuario2 from '../img/usuario2.jpg'
import usuario3 from '../img/usuario3.jpg'
import usuario4 from '../img/usuario4.jpg'
import usuario5 from '../img/usuario5.jpg'

const faqData = [
    { question: 'What is rank boosting?', answer: 'Rank boosting is  a specialized service for players who want to quickly and effectively improve their ranking in Overwatch. Our team of professional players is dedicated to elevating your rank, allowing you to enjoy better matches, rewards, and a more fulfilling gaming experience.' },
    { question: 'Is rank boosting safe?', answer: 'Yes, we use safe methods and protect your account throughout the process. Our team adheres to game policies to avoid any risks.' },
    { question: 'How long will the boosting take?', answer: 'The time varies depending on your current rank and the goal you want to achieve. However, our team strives to complete the service as quickly as possible.' },
    { question: 'Will I have access to my account during the boosting?', answer: 'We recommend not accessing your account while the service is being performed to avoid any issues. We will notify you as soon as the boosting is complete.' },
    { question: 'Which games offer rank boosting?', answer: 'We offer rank boosting for several competitive games, including Overwatch, League of Legends, Valorant, and more. Please check our store for the full list.' },
    { question: "What if I'm not satisfied with the result?", answer: "We strive to provide results that meet your expectations. If you're not satisfied, please contact us, and we will do our best to resolve the issue." },
    { question: 'Can I choose the rank I want to achieve?', answer: 'Yes, you can choose the heroes that will be played for the boost.' },
    { question: 'Do you offer refunds?', answer: 'Our refund policy depends on specific circumstances. Please check our terms and conditions for more details.' }
];


function Home() {
    const logos = [logo1, logo2, logo3, logo4, logo5];
    const [index, setIndex] = useState(0);
    const [isSliding, setIsSliding] = useState(false); // Estado de animación deslizante
    const [buttonPressed, setButtonPressed] = useState(null); // Estado para rastrear el botón presionado
    const [autoSlide, setAutoSlide] = useState(true); // Estado para controlar el deslizamiento automático
    const [expandedIndex, setExpandedIndex] = useState(null);

    const toggleAnswer = (index) => {
        setExpandedIndex(expandedIndex === index ? null : index);
    };

    
    const next = () => {
        setButtonPressed("right"); // Indica que se presionó el botón derecho
        setIsSliding(true);
        setAutoSlide(false); // Desactiva el deslizamiento automático
        setTimeout(() => {
            setIndex((prevIndex) => (prevIndex + 1) % logos.length);
            setIsSliding(false);
            setTimeout(() => setAutoSlide(true), 2000); // Reactiva el deslizamiento automático después de 2 segundos
        }, 500);
    };
    
    const prev = () => {
        setButtonPressed("left"); // Indica que se presionó el botón izquierdo
        setIsSliding(true);
        setAutoSlide(false); // Desactiva el deslizamiento automático
        setTimeout(() => {
            setIndex((prevIndex) => (prevIndex - 1 + logos.length) % logos.length);
            setIsSliding(false);
            setTimeout(() => setAutoSlide(true), 2000); // Reactiva el deslizamiento automático después de 2 segundos
        }, 500);
    };
    
    useEffect(() => {
        const intervalId = setInterval(() => {
            if (autoSlide) {
                next(); // Desliza automáticamente si autoSlide está habilitado
            }
        }, 5000); // Deslizamiento automático hacia la izquierda
        return () => clearInterval(intervalId);
    }, [autoSlide]); // Añadir autoSlide como dependencia
    
    const displayedLogos = [
        logos[index % logos.length],
        logos[(index + 1) % logos.length],
        logos[(index + 2) % logos.length],
    ];
    
    useEffect(() => {
        const handleScroll = () => {
            const cards = document.querySelectorAll('.card-container-home');
            cards.forEach(card => {
                const rect = card.getBoundingClientRect();
                const windowHeight = window.innerHeight;
    
                // Verifica si el card está en vista
                if (rect.top < windowHeight * 0.8) { // Cambia el 0.8 si quieres ajustar el punto de activación
                    card.classList.add('animated');
                }
            });
        };
    
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    
    

    return (
        <div>
            <Navbar /> {/* Incluye el Navbar */}
            <div className="container-home">
                <div className="main-content">
                    <div className="text-content">
                        <h1>Boost Your Game to New <span>Heights!</span></h1>
                        <h2>We offer boosting services and account sales for Overwatch, Fortnite, Genshin Impact, Marvel Rivals and Deadlock. Quickly improve your rank and access accounts with exclusive skins. Our team guarantees a safe and reliable service. Find the perfect account for you and enhance your gaming experience. Transform your game with us!</h2>
                    </div>
                    <div className="video-content">
                        <img src={exampleImage} alt='imagen-alien'/>
                    </div>
                </div>

                {/* New section for logos with full width */}
                <section className="logo-section-home">
                    <button className="nav-button left" onClick={prev}>◀</button>
                    <div className="logo-container">
                        {displayedLogos.map((logo, idx) => {
                            let animationClass = "";
                            // Define las URLs de manera fija, independientemente del índice
                            const logoLinks = [
                                { src: logo1, url: "/fortnite" },
                                { src: logo2, url: "/overwatch" },
                                { src: logo3, url: "/deadlock" },
                                { src: logo4, url: "/genshin" },
                                { src: logo5, url: "/rivals" }
                            ];

                            const logoData = logoLinks[(index + idx) % logoLinks.length]; // Obtener la URL y el logo correspondiente

                            if (isSliding) {
                                if (buttonPressed === "right") {
                                    // Animaciones para el botón derecho
                                    if (idx === 0) {
                                        animationClass = "logo-slide-out-left"; // LOGO1 se desplaza hacia la izquierda y desaparece
                                    } else if (idx === 1) {
                                        animationClass = "logo-slide-to-left"; // LOGO2 se desplaza hacia LOGO1
                                    } else if (idx === 2) {
                                        animationClass = "logo-slide-to-left"; // LOGO3 se desplaza hacia LOGO2
                                    }
                                } else if (buttonPressed === "left") {
                                    // Animaciones para el botón izquierdo
                                    if (idx === 0) {
                                        animationClass = "logo-slide-to-right"; // LOGO1 se desplaza hacia la derecha y desaparece
                                    } else if (idx === 1) {
                                        animationClass = "logo-slide-to-right"; // LOGO2 se desplaza hacia LOGO1
                                    } else if (idx === 2) {
                                        animationClass = "logo-slide-to-right2"; // LOGO3: Aparece desde la izquierda
                                    }
                                }
                            }

                            return (
                                <a href={logoData.url} key={idx} className={`logo-link-${idx + 1}`}>
                                    <img
                                        src={logoData.src}
                                        alt={`Logo ${idx + 1}`}
                                        className={`logo-image-${idx + 1} ${animationClass}`}
                                    />
                                </a>
                            );
                        })}
                    </div>
                    <button className="nav-button right" onClick={next}>▶</button>
                </section>




                
                <section class="highlight-section-home">
                    <h1 class="highlight-title-home">Why Are We the Best Choice for You?</h1>
                    <div class="highlight-cards-home">
                        <div class="card-container-home">
                            <div class="card-home">
                                <h2 class="card-title-home">Guaranteed Security and Confidentiality</h2>
                                    <img src={alien1} alt="Logo 1" class="card-logo-home"/>
                                    <p class="card-text-home">Our dedicated team ensures that all customer accounts and data are protected with advanced security protocols. We understand the importance of privacy in gaming, and we work diligently to keep your information safe and confidential at all times.</p>
                                </div>
                        </div>
                        <div class="card-container-home">
                            <div class="card-home">
                                <h2 class="card-title-home">Fast and Efficient Results</h2>
                                    <img src={alien2} alt="Logo 1" class="card-logo-home"/>
                                <p class="card-text-home">With Alien Boost, you’ll achieve rank improvements in record time, thanks to a professional team maximizing performance in session. We deliver quick results without compromising quality, so you see immediate progress in your games.</p>
                            </div>
                        </div>
                        <div class="card-container-home">
                            <div class="card-home">
                                <h2 class="card-title-home">Personalized Support</h2>
                                    <img src={alien3} alt="Logo 1" class="card-logo-home"/>
                                    <p class="card-text-home">We take pride in offering round-the-clock customer support, with a dedicated team readily available 24/7 to answer your questions and tailor the service to your gaming needs. Your satisfaction is our top priority, and we’re here to help you every step of the way.</p>
                                </div>
                        </div> 
                    </div>
                </section>

                <section class="discord-section">
                <div class="discord-card">
                    <img src={usuario1} alt="Uvdjet" class="discord-img"/>
                    <h3 class="discord-name">Uvdjet</h3>
                    <blockquote class="discord-quote">
                    "Absolutely fantastic service, everything went smoothly. I’ve been a customer for years, and I’m always satisfied. Highly recommend this seller!"
                    </blockquote>
                </div>
                <div class="discord-card">
                    <img src={usuario2} alt="el_luzho" class="discord-img"/>
                    <h3 class="discord-name">el_luzho</h3>
                    <blockquote class="discord-quote">
                    "Great experience, very reliable. Transaction was quick, and everything was as promised. Definitely a trusted seller!"
                    </blockquote>
                </div>
                <div class="discord-card">
                    <img src={usuario3} alt="yokata" class="discord-img"/>
                    <h3 class="discord-name">yokata</h3>
                    <blockquote class="discord-quote">
                    "Super fast and efficient service, everything was handled professionally. I’ll be coming back for sure!"
                    </blockquote>
                </div>
                <div class="discord-card">
                    <img src={usuario4} alt="NurBlu" class="discord-img"/>
                    <h3 class="discord-name">NurBlu</h3>
                    <blockquote class="discord-quote">
                    "Quick responses and smooth process, couldn’t ask for a better experience. Trusted and recommended seller!"
                    </blockquote>
                </div>
                <div class="discord-card">
                    <img src={usuario5} alt="Maatooh" class="discord-img"/>
                    <h3 class="discord-name">Maatooh</h3>
                    <blockquote class="discord-quote">
                    "Always a great experience, seller is very trustworthy and reliable. Everything went as planned, would definitely buy again."
                    </blockquote>
                </div>
                </section>

                <div style={{ padding: '20px' }} className='faq'>
                <h1>Frequently Asked Questions</h1>
                {faqData.map((faq, index) => (
                    <div key={index} className="faq-item">
                        <div 
                            className="faq-question" 
                            onClick={() => toggleAnswer(index)}
                        >
                            {faq.question}
                            <span className={`faq-arrow ${expandedIndex === index ? 'expanded' : ''}`}>
                                ▼
                            </span>
                        </div>
                        <div className={`faq-answer ${expandedIndex === index ? 'active' : ''}`}>
                            {faq.answer}
                        </div>
                    </div>
                ))}
            </div>


            </div>
            <Footer />
        </div>
    );
}

export default Home;
