import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from './navbar'; // Ensure Navbar is part of the app
import '../styles/register.css';

function Register() {
    const [email, setEmail] = useState('');
    const [username, setUsername] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false); // Estado para el spinner
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(''); // Reset error message before each submit
        setLoading(true); // Activamos el spinner cuando empieza el proceso

        try {
            const response = await fetch('https://alienboost.onrender.com/register', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    correo: email,
                    username,
                    nombre: firstName,
                    apellido: lastName,
                    contrasena: password,
                }),
            });

            if (!response.ok) {
                const data = await response.json(); // Get error details from the response
                throw new Error(data.detail || 'Registration error. Please check your data.');
            }

            // Redirect to login on successful registration
            navigate('/login');
        } catch (error) {
            setError(error.message); // Set the error message from the response
        } finally {
            setLoading(false); // Desactivamos el spinner al finalizar
        }
    };

    return (
        <>
            <Navbar />
            <div className="register-container">
                {loading ? ( // Si loading es true, mostramos el spinner
                    <div className="login-spinner">
                        <div className="spinner"></div>
                        <p>Loading...</p>
                    </div>
                ) : (
                    <>
                        <h2 className="register-title">Sign Up</h2>
                        <form onSubmit={handleSubmit} className="register-form">
                            <label htmlFor="email" className="register-label">Email</label>
                            <input
                                type="email"
                                id="email"
                                className="register-input"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />

                            <label htmlFor="username" className="register-label">Username</label>
                            <input
                                type="text"
                                id="username"
                                className="register-input"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                required
                            />

                            <label htmlFor="firstName" className="register-label">First Name</label>
                            <input
                                type="text"
                                id="firstName"
                                className="register-input"
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                                required
                            />

                            <label htmlFor="lastName" className="register-label">Last Name</label>
                            <input
                                type="text"
                                id="lastName"
                                className="register-input"
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                                required
                            />

                            <label htmlFor="password" className="register-label">Password</label>
                            <input
                                type="password"
                                id="password"
                                className="register-input"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />

                            {error && <p className="error-message">{error}</p>} {/* Display error message */}

                            <button type="submit" className="register-button">Sign Up</button>
                        </form>

                        {/* Link to login */}
                        <p className="login-prompt">Already have an account? <a href="/login" className="login-link">Log in</a></p>
                    </>
                )}
            </div>
        </>
    );
}

export default Register;
