// Footer.jsx
import React from 'react';
import '../styles/footer.css'; // Asegúrate de importar el CSS
import logo from '../img/logo.png'; // Importa el logo

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-top">
                <div className="footer-logo">
                    <img src={logo} alt="Logo" className="footer-logo-img" />
                </div>
                <div className="footer-divider"></div>
                <div className="footer-right">
                    <p className="footer-right-text">All rights reserved</p>
                    <p className="footer-right-text">© 2024 Alien Boost</p>
                    <p className="footer-right-text">SSL Secured</p>
                </div>
            </div>
            <div className="footer-bottom">
                <p className="footer-disclaimer">
                    Alien Boost is not endorsed or affiliated with Activision Blizzard, Riot Games, Electronic Arts, miHoYo, Valve Corporation, Epic Games, or Marvel Entertainment in any way, and does not reflect the views or opinions of the entities officially involved in the production or management of Marvel rivals, Overwatch, Fortnite, Genshin Impact, or Dedlock. All trademarks and logos are the property of their respective owners. All submitted artwork remains the copyright of its original creator.
                </p>
                <div className="footer-links">
                    <a href="#about-us" className="footer-link">About Us</a>
                    <a href="/cuentas" className="footer-link">Accounts</a>
                    <a href="/faq" className="footer-link">FAQ</a>
                </div>
                <div className="footer-credits">
                    <p>Created by</p>
                    <a
                        href="https://www.instagram.com/yuyopages/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="footer-instagram"
                    >
                        @yuyopages
                    </a>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
