/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../styles/navbar.css';
import logo from '../img/logo.png'; // Importa el logo

function Navbar() {
    const navigate = useNavigate();
    const userUsername = localStorage.getItem('userUsername');
    const userType = localStorage.getItem('userType'); // Obtener el tipo de usuario
    const [dropdownOpen, setDropdownOpen] = useState(false); // State to manage dropdown visibility
    const [hasNotification, setHasNotification] = useState(false); // State to track if there's a notification
    const [mensajesSinLeer, setMensajesSinLeer] = useState([]);
    const [isMenuOpen, setIsMenuOpen] = useState(false); // State to toggle the mobile menu

    // Función para obtener las conversaciones y comprobar las notificaciones
    const checkNotifications = async () => {
        try {
            const response = await fetch(`https://alienboost.onrender.com/obtener-conversacion/${localStorage.getItem('userId')}`);
            const data = await response.json();
            
            console.log("Datos recibidos desde el servidor:", data);
        
            // Filtra los mensajes no leídos y que no son del usuario logueado
            const unreadMessages = data.mensajes.filter(
                (mensaje) => mensaje.notificacion === 1 && mensaje.remitente !== userUsername
            );
        
            if (unreadMessages.length > 0) {
                console.log("Hay mensajes sin leer de otros usuarios:");
                unreadMessages.forEach((mensaje) => {
                    console.log(`Mensaje ID: ${mensaje.mensaje_id} de ${mensaje.remitente}: ${mensaje.contenido}`);
                });
    
                // Actualiza el estado fuera del ciclo forEach
                setMensajesSinLeer(unreadMessages.map(mensaje => mensaje.mensaje_id));
                console.log("IDs de mensajes sin leer que se están guardando:", unreadMessages.map(mensaje => mensaje.mensaje_id));
                
                setHasNotification(true);
            } else {
                console.log("No hay mensajes sin leer o todos son del usuario logueado.");
                setHasNotification(false);
            }
        } catch (error) {
            console.error("Error al obtener las conversaciones:", error);
        }
    };

    const actualizarNotificacion = async (mensajeIds) => {
        try {
            console.log("Enviando los siguientes IDs de mensajes:", mensajeIds);
    
            // Verifica si mensajeIds es un arreglo de enteros
            if (!Array.isArray(mensajeIds) || mensajeIds.some(id => typeof id !== 'number')) {
                console.error("mensajeIds no es un arreglo de enteros:", mensajeIds);
                return;
            }
    
            // Enviar los IDs como un arreglo directamente en el cuerpo
            const response = await fetch('https://alienboost.onrender.com/actualizar-notificacion', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(mensajeIds)  // Enviamos solo el arreglo directamente
            });
    
            if (response.ok) {
                console.log("Notificación actualizada correctamente");
                checkNotifications(); // Recarga las conversaciones para actualizar el estado de las notificaciones
            } else {
                console.log("Error al actualizar la notificación");
                const errorData = await response.json();
                console.error("Error detalle:", errorData);  // Mostrar detalles de error para depuración
            }
        } catch (error) {
            console.error("Error al llamar al endpoint de actualización de notificación:", error);
        }
    };
    
    const handleMessageClick = () => {
        // Verificamos si hay mensajes sin leer y pasamos el array completo de IDs
        if (mensajesSinLeer && mensajesSinLeer.length > 0) {
            // Directamente pasamos el array de IDs al backend
            actualizarNotificacion(mensajesSinLeer); // Enviamos el array de IDs al backend
        }
    };

    useEffect(() => {
        if (userUsername) {
            checkNotifications(); // Verifica las notificaciones al cargar el componente
        }
    }, [userUsername]);

    const handleLogout = () => {
        // Eliminar los datos de usuario de localStorage
        localStorage.removeItem('userId');
        localStorage.removeItem('userType');
        localStorage.removeItem('userName');
        localStorage.removeItem('userUsername');

        // Redirigir al usuario al Home
        navigate('/');
    };

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen); // Toggle dropdown visibility
    };

    // Función para alternar el menú
    const toggleMenu = () => {
        const menu = document.querySelector('.navbar-menu');
        menu.classList.toggle('active');
    
    // Alternar el ícono de hamburguesa a "X"
    const burger = document.querySelector('.navbar-burger');
    burger.classList.toggle('active');
}

const [isBoostingDropdownOpen, setIsBoostingDropdownOpen] = useState(false);
const dropdownRef = useRef(null); // Usamos ref para el contenedor del dropdown

// Función para alternar el estado del dropdown "Boosting"
const handleBoostingDropdown = (event) => {
    if (window.innerWidth <= 768) {
        event.stopPropagation(); // Evita conflictos con eventos de clic externos
        setIsBoostingDropdownOpen((prevState) => !prevState); // Alterna el estado
    }
};



// Función para cerrar el dropdown si se hace clic fuera de él
const handleClickOutside = (event) => {
  // Verificar si el clic ocurrió fuera del dropdown
  if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
    setIsBoostingDropdownOpen(false); // Cerrar el dropdown
  }
};

// Agregar un listener para cerrar el dropdown si se hace clic fuera de él
useEffect(() => {
  document.addEventListener("click", handleClickOutside);
  return () => document.removeEventListener("click", handleClickOutside); // Limpiar el event listener
}, []);

const [adminDropdownOpen, setAdminDropdownOpen] = useState(false);

    const toggleAdminDropdown = () => {
        setAdminDropdownOpen(!adminDropdownOpen);
    };


    return (
        <nav className="navbar">
            <div className="navbar-brand">
                {userType === "1" ? (
                    <Link to="/admin" className="navbar-item-2 logo">
                        <img src={logo} alt="Logo" className="logo-image" />
                        Alien Boost
                    </Link>
                ) : (
                    <Link to="/" className="navbar-item-2 logo">
                        <img src={logo} alt="Logo" className="logo-image" />
                        Alien Boost
                    </Link>
                )}
            </div>
            <div className={`navbar-menu ${isMenuOpen ? 'is-active' : ''}`}>
                    {userType === "1" ? (
                        <>
                            <Link to="/admin-usuarios" className="navbar-item">Usuarios Registrados</Link>
                            <Link to="/admin-venta" className="navbar-item">Venta Overwatch</Link>
                            
                            {/* Dropdown para Admin */}
                            <div className="navbar-item">
                                <button onClick={toggleAdminDropdown} className="user-dropdown-button">
                                    Boost
                                </button>
                                {adminDropdownOpen && (
                                    <div className="dropdown-menu">
                                        <Link to="/overwatchadmin" className="navbar-item">Overwatch</Link>
                                        <Link to="#" className="navbar-item">Fortnite</Link>
                                        <Link to="#" className="navbar-item">Deadlock</Link>
                                        <Link to="#" className="navbar-item">Genshin Impact</Link>
                                        <Link to="#" className="navbar-item">Rivals</Link>
                                    </div>
                                )}
                            </div>
                        </>
                    ) : (
                    <>
                        <Link to="/" className="navbar-item">Home</Link>

                        <div 
                            className="navbar-item dropdown" 
                            ref={dropdownRef} // Referencia al contenedor del dropdown
                            >
                            <button 
                                className="dropbtn" 
                                onClick={handleBoostingDropdown} // Cambia el estado al hacer clic en el botón
                            >
                                Boosting
                            </button>
                            <div className={`dropdown-content ${isBoostingDropdownOpen ? 'open' : ''}`}>
                                <Link to="/overwatch" className="navbar-item">Overwatch</Link>
                                <Link to="/fortnite" className="navbar-item">Fortnite</Link>
                                <Link to="/deadlock" className="navbar-item">Deadlock</Link>
                                <Link to="/genshin" className="navbar-item">Genshin Impact</Link>
                                <Link to="/rivals" className="navbar-item">Rivals</Link>
                            </div>
                        </div>
                        <Link to="/cuentas" className="navbar-item">Accounts</Link>
                    </>
                )}

                {/* Mostrar el botón con el SVG solo si el usuario no es tipo 1 y está logueado */}
                {userType !== "1" && userUsername && (
                    <Link to="/carrito" className="navbar-item carrito-button">
                        <svg 
                            version="1.1" 
                            id="Capa_1" 
                            xmlns="http://www.w3.org/2000/svg" 
                            viewBox="0 0 902.86 902.86" 
                            className="carrito-icon"
                        >
                            <path d="M671.504,577.829l110.485-432.609H902.86v-68H729.174L703.128,179.2L0,178.697l74.753,399.129h596.751V577.829z M685.766,247.188l-67.077,262.64H131.199L81.928,246.756L685.766,247.188z"></path>
                            <path d="M578.418,825.641c59.961,0,108.743-48.783,108.743-108.744s-48.782-108.742-108.743-108.742H168.717 c-59.961,0-108.744,48.781-108.744,108.742s48.782,108.744,108.744,108.744c59.962,0,108.743-48.783,108.743-108.744 c0-14.4-2.821-28.152-7.927-40.742h208.069c-5.107,12.59-7.928,26.342-7.928,40.742 C469.675,776.858,518.457,825.641,578.418,825.641z M209.46,716.897c0,22.467-18.277,40.744-40.743,40.744 c-22.467,0-40.744-18.277-40.744-40.744s18.277-40.744,40.744-40.744C191.183,676.153,209.46,694.43,209.46,716.897z M578.418,716.897c0,22.467-18.277,40.744-40.743,40.744 c-22.467,0-40.744-18.277-40.744-40.744s18.277-40.744,40.744-40.744C560.141,676.153,578.418,694.43,578.418,716.897z"></path>
                        </svg>
                        {hasNotification && (
                            <span className="notification-dot"></span>  // El punto rojo indicando notificación
                        )}
                    </Link>
                )}

                {/* Dropdown de usuario */}
                {userUsername ? (
                    <div className="navbar-item user-menu">
                        <button 
                            onClick={toggleDropdown} 
                            className={`user-dropdown-button ${dropdownOpen ? 'dropdown-open' : ''}`}
                        >
                            {userUsername} <span className={`dropdown-arrow ${dropdownOpen ? 'rotate' : ''}`}>▼</span>
                        </button>
                        {dropdownOpen && (
                            <div className="dropdown-menu">
                                <Link to="/mi-cuenta" className="dropdown-item">My Account</Link>
                                <Link to="/mensajes" className="dropdown-item" onClick={handleMessageClick}>
                                    Messages 
                                    {hasNotification && <span className="notification-bubble"></span>}
                                </Link>
                                {userType !== "1" && userUsername && (
                                    <Link to="/ordenes" className="dropdown-item">My Orders</Link>
                                )}

                                <button onClick={handleLogout} className="dropdown-item">Logout</button>
                            </div>
                        )}
                    </div>
                ) : (
                    <Link to="/login" className="navbar-item login-button-2">Log In</Link>
                )}

            </div>

            {/* Botón del menú de hamburguesa para dispositivos móviles */}
            <button className="navbar-burger" onClick={toggleMenu}>
                <span></span>
                <span></span>
                <span></span>
            </button>
        </nav>
    );
}

export default Navbar;
