/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react';
import Footer from './footer';
import Navbar from './navbar';
import '../styles/overwatch.css';
import bronze from '../img/bronze.png';
import silver from '../img/silver.png';
import gold from '../img/gold.png';
import platinum from '../img/platinum.png';
import diamond from '../img/diamond.png';
import master from '../img/master.png';
import GrandMaster from '../img/grandmaster.png';
import champion from '../img/champion.png';
import top500 from '../img/top500.png';
import dps from '../img/dps.png'
import healer from '../img/healer.png'
import tank from '../img/tank.png'
import open from '../img/open.png'
import placement from '../img/placement.png'
import battlepass from '../img/battlepass.png'
import quickplay from '../img/quickplay.png'
import wins from '../img/wins.png'
import axios from 'axios';

const Overwatch = () => {
    const [selectedRole, setSelectedRole] = useState('dps');
    const userUsername = localStorage.getItem('userUsername');
    const [startRank, setStartRank] = useState('Bronze 5');
    const [endRank, setEndRank] = useState('Bronze 1');
    const [totalPrice, setTotalPrice] = useState(0);
    const [errorMessage, setErrorMessage] = useState('');
    const [additionalOptions, setAdditionalOptions] = useState({
        express: false,
        superExpress: false,
        specificHeroes: false,
        stream: false,
        oneTrick: false,
        soloOnlyQueue: false,
        duoBoost: false
    });
    const [isButtonDisabled, setIsButtonDisabled] = useState(true); // Track if the button is disabled
    const [selectedOption, setSelectedOption] = useState('rankBoost'); // Default option is 'rankBoost'
    const [quantity, setQuantity] = useState(10);
    const [gameType, setGameType] = useState('games'); // 'games' o 'wins'
    const [priceCalculated, setPriceCalculated] = useState(false);  // Estado para controlar si el precio ha sido calculado
    const [quantityChanged, setQuantityChanged] = useState(false);
    const [gameTypeChanged, setGameTypeChanged] = useState(false); // Estado para saber si el tipo de juego cambió
    const [currentLevel, setCurrentLevel] = useState(1); // Nivel actual
    const [desiredLevel, setDesiredLevel] = useState(2); // Nivel deseado
    const [activeSlider, setActiveSlider] = useState(null);
    const [selectedRank, setSelectedRank] = useState("bronze");
    const [selectedGames, setSelectedGames] = useState(1);
    const [selectedWins, setSelectedWins] = useState(1);


    const rankImages2 = {
        bronze,
        silver,
        gold,
        platinum,
        diamond,
        master,
        GrandMaster,
        champion,
    };

    const rankImages3 = {
        bronze,
        silver,
        gold,
        platinum,
        diamond,
        master,
        GrandMaster,
        top500,
    };

    const priceList2 = {
        bronze: 2,
        silver: 2.25,
        gold: 2.9,
        platinum: 3.9,
        diamond: 4.0,
        master: 6,
        GrandMaster: 9,
        champion: 10,
    };

    const priceList3 = {
        bronze: 1.50,
        silver: 2,
        gold: 2.5,
        platinum: 3,
        diamond: 5,
        master: 10,
        GrandMaster: 20,
        top500: 30,
    };



    const handleQuantityChange = (e) => {
        setQuantity(e.target.value);
    };

    const handleGameTypeChange = (e) => {
        setGameType(e.target.value);
    };

    useEffect(() => {
        const userUsername = localStorage.getItem('userUsername');
        if (userUsername) {
            setIsButtonDisabled(true); // Si el username existe, habilitamos el botón
        } else {
            setIsButtonDisabled(true); // Si no existe, deshabilitamos el botón
        }
    }, []); // Este useEffect se ejecutará solo una vez cuando el componente se monte

    const handleAddToCart = async () => {
        // Verificar si la opción seleccionada es 'rankBoost'
        if (selectedOption === 'rankBoost') {
            // Obtener los datos desde el formulario o del estado
            const usuario_id = parseInt(localStorage.getItem('userId'));  // Obtener el usuario_id del localStorage
        
            // Construir la descripción usando las variables de rango y rol seleccionados
            let descripcion = `${startRank} to ${endRank}, ${selectedRole}`;
        
            // Crear objeto BoostRequest
            const boostRequest = {
                juego: "Overwatch",  // Juego fijo
                precio_calculado: totalPrice,
                descripcion: descripcion,
                tipo: 1,  // Tipo de boost
                descripcion_detallada: "",  // Iniciar con una cadena vacía
                usuario_id: usuario_id  // Enviar el usuario_id del localStorage
            };
        
            // Agregar descripción detallada si alguna opción adicional está activa
            if (additionalOptions.express) {
                boostRequest.descripcion_detallada += " Express, ";
            }
            if (additionalOptions.superExpress) {
                boostRequest.descripcion_detallada += " Super Express, ";
            }
            if (additionalOptions.duoBoost) {
                boostRequest.descripcion_detallada += " Duo Boost, ";
            }
            if (additionalOptions.specificHeroes) {
                boostRequest.descripcion_detallada += " Specific Heroes, ";
            }
            if (additionalOptions.stream) {
                boostRequest.descripcion_detallada += " Stream, ";
            }
            if (additionalOptions.oneTrick) {
                boostRequest.descripcion_detallada += " One Trick, ";
            }
            if (additionalOptions.soloOnlyQueue) {
                boostRequest.descripcion_detallada += " Solo Only Queue";
            }
        
            // Imprimir por consola el objeto boostRequest que se enviará al backend
            console.log("Datos que se enviarán al backend (Rank Boost):", boostRequest);
        
            // Realizar la petición POST al backend para crear el boost y agregar al carrito
            try {
                const response = await axios.post('https://alienboost.onrender.com/crear-boost', boostRequest);
                console.log(response.data.message);  // Mostrar mensaje de éxito
        
                // Mostrar alerta en inglés
                alert("Boost added to the cart successfully!");
        
                // Opcional: Puedes redirigir al usuario o mostrar un mensaje de éxito en la interfaz
            } catch (error) {
                console.error('Error al agregar boost al carrito:', error.response.data.detail);  // Manejar errores de la petición
                // Opcional: Mostrar mensaje de error en la interfaz
            }
    
        // Verificar si la opción seleccionada es 'quickplayBoost'
        } else if (selectedOption === 'quickplayBoost') {
            // Obtener los datos desde el formulario o del estado
            const usuario_id = parseInt(localStorage.getItem('userId'));  // Obtener el usuario_id del localStorage
        
            // Construir la descripción usando las variables de tipo de juego y cantidad
            let descripcion = `${gameType}, ${quantity}`;
        
            // Crear objeto BoostRequest
            const boostRequest = {
                juego: "Overwatch",  // Juego fijo
                precio_calculado: totalPrice,
                descripcion: descripcion,
                tipo: 2,  // Tipo de boost para quickplay
                descripcion_detallada: "",  // Iniciar con una cadena vacía
                usuario_id: usuario_id  // Enviar el usuario_id del localStorage
            };
        
            // Agregar descripción detallada si alguna opción adicional está activa
            if (additionalOptions.express) {
                boostRequest.descripcion_detallada += " Express, ";
            }
            if (additionalOptions.superExpress) {
                boostRequest.descripcion_detallada += " Super Express, ";
            }
            if (additionalOptions.duoBoost) {
                boostRequest.descripcion_detallada += " Duo Boost, ";
            }
            if (additionalOptions.specificHeroes) {
                boostRequest.descripcion_detallada += " Specific Heroes, ";
            }
            if (additionalOptions.stream) {
                boostRequest.descripcion_detallada += " Stream, ";
            }
            if (additionalOptions.oneTrick) {
                boostRequest.descripcion_detallada += " One Trick, ";
            }
            if (additionalOptions.soloOnlyQueue) {
                boostRequest.descripcion_detallada += " Solo Only Queue";
            }
        
            // Imprimir por consola el objeto boostRequest que se enviará al backend
            console.log("Datos que se enviarán al backend (Quickplay Boost):", boostRequest);
        
            // Realizar la petición POST al backend para crear el boost y agregar al carrito
            try {
                const response = await axios.post('https://alienboost.onrender.com/crear-boost', boostRequest);
                console.log(response.data.message);  // Mostrar mensaje de éxito
        
                // Mostrar alerta en inglés
                alert("Boost added to the cart successfully!");
        
                // Opcional: Puedes redirigir al usuario o mostrar un mensaje de éxito en la interfaz
            } catch (error) {
                console.error('Error al agregar boost al carrito:', error.response.data.detail);  // Manejar errores de la petición
                // Opcional: Mostrar mensaje de error en la interfaz
            }
        } else if (selectedOption === 'battlepassBoost') {
            const usuario_id = parseInt(localStorage.getItem('userId'));  // Obtener el usuario_id del localStorage
        
            // Construir la descripción usando las variables de tipo de juego y cantidad
            let descripcion = `level ${currentLevel} to level ${desiredLevel} battlepass`;
        
            // Crear objeto BoostRequest
            const boostRequest = {
                juego: "Overwatch",  // Juego fijo
                precio_calculado: totalPrice,
                descripcion: descripcion,
                tipo: 3,  // Tipo de boost para quickplay
                descripcion_detallada: "",  // Iniciar con una cadena vacía
                usuario_id: usuario_id  // Enviar el usuario_id del localStorage
            };
        
            // Agregar descripción detallada si alguna opción adicional está activa
            if (additionalOptions.express) {
                boostRequest.descripcion_detallada += " Express, ";
            }
            if (additionalOptions.superExpress) {
                boostRequest.descripcion_detallada += " Super Express, ";
            }
            if (additionalOptions.duoBoost) {
                boostRequest.descripcion_detallada += " Duo Boost, ";
            }
            if (additionalOptions.specificHeroes) {
                boostRequest.descripcion_detallada += " Specific Heroes, ";
            }
            if (additionalOptions.stream) {
                boostRequest.descripcion_detallada += " Stream, ";
            }
            if (additionalOptions.oneTrick) {
                boostRequest.descripcion_detallada += " One Trick, ";
            }
            if (additionalOptions.soloOnlyQueue) {
                boostRequest.descripcion_detallada += " Solo Only Queue";
            }
        
            // Imprimir por consola el objeto boostRequest que se enviará al backend
            console.log("Datos que se enviarán al backend (Quickplay Boost):", boostRequest);
        
            // Realizar la petición POST al backend para crear el boost y agregar al carrito
            try {
                const response = await axios.post('https://alienboost.onrender.com/crear-boost', boostRequest);
                console.log(response.data.message);  // Mostrar mensaje de éxito
        
                // Mostrar alerta en inglés
                alert("Boost added to the cart successfully!");
        
                // Opcional: Puedes redirigir al usuario o mostrar un mensaje de éxito en la interfaz
            } catch (error) {
                console.error('Error al agregar boost al carrito:', error.response.data.detail);  // Manejar errores de la petición
                // Opcional: Mostrar mensaje de error en la interfaz
            }
        }else if (selectedOption === 'placementBoost') {
            const usuario_id = parseInt(localStorage.getItem('userId'));  // Obtener el usuario_id del localStorage
        
            // Construir la descripción usando las variables de tipo de juego y cantidad
            let descripcion = `rank ${selectedRank}, ${selectedGames} games`;
        
            // Crear objeto BoostRequest
            const boostRequest = {
                juego: "Overwatch",  // Juego fijo
                precio_calculado: totalPrice,
                descripcion: descripcion,
                tipo: 4,  // Tipo de boost para quickplay
                descripcion_detallada: "",  // Iniciar con una cadena vacía
                usuario_id: usuario_id  // Enviar el usuario_id del localStorage
            };
        
            // Agregar descripción detallada si alguna opción adicional está activa
            if (additionalOptions.express) {
                boostRequest.descripcion_detallada += " Express, ";
            }
            if (additionalOptions.superExpress) {
                boostRequest.descripcion_detallada += " Super Express, ";
            }
            if (additionalOptions.duoBoost) {
                boostRequest.descripcion_detallada += " Duo Boost, ";
            }
            if (additionalOptions.specificHeroes) {
                boostRequest.descripcion_detallada += " Specific Heroes, ";
            }
            if (additionalOptions.stream) {
                boostRequest.descripcion_detallada += " Stream, ";
            }
            if (additionalOptions.oneTrick) {
                boostRequest.descripcion_detallada += " One Trick, ";
            }
            if (additionalOptions.soloOnlyQueue) {
                boostRequest.descripcion_detallada += " Solo Only Queue";
            }
        
            // Imprimir por consola el objeto boostRequest que se enviará al backend
            console.log("Datos que se enviarán al backend (Placement Boost):", boostRequest);
        
            // Realizar la petición POST al backend para crear el boost y agregar al carrito
            try {
                const response = await axios.post('https://alienboost.onrender.com/crear-boost', boostRequest);
                console.log(response.data.message);  // Mostrar mensaje de éxito
        
                // Mostrar alerta en inglés
                alert("Boost added to the cart successfully!");
        
                // Opcional: Puedes redirigir al usuario o mostrar un mensaje de éxito en la interfaz
            } catch (error) {
                console.error('Error al agregar boost al carrito:', error.response.data.detail);  // Manejar errores de la petición
                // Opcional: Mostrar mensaje de error en la interfaz
            }
        }else if (selectedOption === 'winBoost') {
            const usuario_id = parseInt(localStorage.getItem('userId'));  // Obtener el usuario_id del localStorage
        
            // Construir la descripción usando las variables de tipo de juego y cantidad
            let descripcion = `rank ${selectedRank}, ${selectedWins} wins`;
        
            // Crear objeto BoostRequest
            const boostRequest = {
                juego: "Overwatch",  // Juego fijo
                precio_calculado: totalPrice,
                descripcion: descripcion,
                tipo: 5,  // Tipo de boost para quickplay
                descripcion_detallada: "",  // Iniciar con una cadena vacía
                usuario_id: usuario_id  // Enviar el usuario_id del localStorage
            };
        
            // Agregar descripción detallada si alguna opción adicional está activa
            if (additionalOptions.express) {
                boostRequest.descripcion_detallada += " Express, ";
            }
            if (additionalOptions.superExpress) {
                boostRequest.descripcion_detallada += " Super Express, ";
            }
            if (additionalOptions.duoBoost) {
                boostRequest.descripcion_detallada += " Duo Boost, ";
            }
            if (additionalOptions.specificHeroes) {
                boostRequest.descripcion_detallada += " Specific Heroes, ";
            }
            if (additionalOptions.stream) {
                boostRequest.descripcion_detallada += " Stream, ";
            }
            if (additionalOptions.oneTrick) {
                boostRequest.descripcion_detallada += " One Trick, ";
            }
            if (additionalOptions.soloOnlyQueue) {
                boostRequest.descripcion_detallada += " Solo Only Queue";
            }
        
            // Imprimir por consola el objeto boostRequest que se enviará al backend
            console.log("Datos que se enviarán al backend (Placement Boost):", boostRequest);
        
            // Realizar la petición POST al backend para crear el boost y agregar al carrito
            try {
                const response = await axios.post('https://alienboost.onrender.com/crear-boost', boostRequest);
                console.log(response.data.message);  // Mostrar mensaje de éxito
        
                // Mostrar alerta en inglés
                alert("Boost added to the cart successfully!");
        
                // Opcional: Puedes redirigir al usuario o mostrar un mensaje de éxito en la interfaz
            } catch (error) {
                console.error('Error al agregar boost al carrito:', error.response.data.detail);  // Manejar errores de la petición
                // Opcional: Mostrar mensaje de error en la interfaz
            }
        }
        else {
            console.log("The selected option is neither 'rankBoost' nor 'quickplayBoost'. No action taken.");
        }
    };
    
    useEffect(() => {
        setIsButtonDisabled(true); // Deshabilitar el botón al cambiar `currentLevel` o `desiredLevel`
        setPriceCalculated(false); // Indicar que el precio no está calculado
    }, [currentLevel, desiredLevel]);

    
        // Manejo de cambios en `currentLevel`
    const handleCurrentLevelChange = (e) => {
        setCurrentLevel(parseInt(e.target.value, 10));
    };

    // Manejo de cambios en `desiredLevel`
    const handleDesiredLevelChange = (e) => {
        setDesiredLevel(parseInt(e.target.value, 10));
    };

    const handleRoleChange = (role) => {
        setSelectedRole(role);
    };

    const handleArrowClick = (direction) => {
        if (direction === 'up' && quantity < 100) {
            setQuantity(prevQuantity => prevQuantity + 1);
        } else if (direction === 'down' && quantity > 1) {
            setQuantity(prevQuantity => prevQuantity - 1);
        }
    };

    // Mapa de las imágenes de los rangos
    const rankImages = {
        "Bronze 5": bronze,
        "Bronze 4": bronze,
        "Bronze 3": bronze,
        "Bronze 2": bronze,
        "Bronze 1": bronze,
        "Silver 5": silver,
        "Silver 4": silver,
        "Silver 3": silver,
        "Silver 2": silver,
        "Silver 1": silver,
        "Gold 5": gold,
        "Gold 4": gold,
        "Gold 3": gold,
        "Gold 2": gold,
        "Gold 1": gold,
        "Platinum 5": platinum,
        "Platinum 4": platinum,
        "Platinum 3": platinum,
        "Platinum 2": platinum,
        "Platinum 1": platinum,
        "Diamond 5": diamond,
        "Diamond 4": diamond,
        "Diamond 3": diamond,
        "Diamond 2": diamond,
        "Diamond 1": diamond,
        "Master 5": master,
        "Master 4": master,
        "Master 3": master,
        "Master 2": master,
        "Master 1": master,
        "Grand Master 5": GrandMaster,
        "Grand Master 4": GrandMaster,
        "Grand Master 3": GrandMaster,
        "Grand Master 2": GrandMaster,
        "Grand Master 1": GrandMaster,
        "Champion 5": champion
    };

    const priceList = {
        "Bronze 5": { "Bronze 4": 3.99, "Bronze 3": 3.99, "Bronze 2": 3.99, "Bronze 1": 3.99, "Silver 5": 4.99 },
        "Bronze 4": { "Bronze 3": 3.99, "Bronze 2": 3.99, "Bronze 1": 3.99, "Silver 5": 4.99 },
        "Bronze 3": { "Bronze 2": 3.99, "Bronze 1": 3.99, "Silver 5": 4.99 },
        "Bronze 2": { "Bronze 1": 3.99, "Silver 5": 4.99 },
        "Bronze 1": { "Silver 5": 4.99 },
        "Silver 5": { "Silver 4": 4.99, "Silver 3": 4.99, "Silver 2": 4.99, "Silver 1": 4.99, "Gold 5": 6.99 },
        "Silver 4": { "Silver 3": 4.99, "Silver 2": 4.99, "Silver 1": 4.99, "Gold 5": 6.99 },
        "Silver 3": { "Silver 2": 4.99, "Silver 1": 4.99, "Gold 5": 6.99 },
        "Silver 2": { "Silver 1": 4.99, "Gold 5": 6.99 },
        "Silver 1": { "Gold 5": 6.99 },
        "Gold 5": { "Gold 4": 6.99, "Gold 3": 6.99, "Gold 2": 6.99, "Gold 1": 6.99, "Platinum 5": 9.99 },
        "Gold 4": { "Gold 3": 6.99, "Gold 2": 6.99, "Gold 1": 6.99, "Platinum 5": 9.99 },
        "Gold 3": { "Gold 2": 6.99, "Gold 1": 6.99, "Platinum 5": 9.99 },
        "Gold 2": { "Gold 1": 6.99, "Platinum 5": 9.99 },
        "Gold 1": { "Platinum 5": 9.99 },
        "Platinum 5": { "Platinum 4": 9.99, "Platinum 3": 9.99, "Platinum 2": 9.99, "Platinum 1": 9.99, "Diamond 5": 12.99 },
        "Platinum 4": { "Platinum 3": 9.99, "Platinum 2": 9.99, "Platinum 1": 9.99, "Diamond 5": 12.99 },
        "Platinum 3": { "Platinum 2": 9.99, "Platinum 1": 9.99, "Diamond 5": 12.99 },
        "Platinum 2": { "Platinum 1": 9.99, "Diamond 5": 12.99 },
        "Platinum 1": { "Diamond 5": 12.99 },
        "Diamond 5": { "Diamond 4": 12.99, "Diamond 3": 13.99, "Diamond 2": 15.99, "Diamond 1": 20.99, "Master 5": 20.99 },
        "Diamond 4": { "Diamond 3": 13.99, "Diamond 2": 15.99, "Diamond 1": 20.99, "Master 5": 20.99 },
        "Diamond 3": { "Diamond 2": 15.99, "Diamond 1": 20.99, "Master 5": 20.99 },
        "Diamond 2": { "Diamond 1": 20.99, "Master 5": 20.99 },
        "Diamond 1": { "Master 5": 20.99 },
        "Master 5": { "Master 4": 24.99, "Master 3": 29.99, "Master 2": 39.99, "Master 1": 44.99, "Grand Master 5": 44.99 },
        "Master 4": { "Master 3": 29.99, "Master 2": 39.99, "Master 1": 44.99, "Grand Master 5": 44.99 },
        "Master 3": { "Master 2": 39.99, "Master 1": 44.99, "Grand Master 5": 44.99 },
        "Master 2": { "Master 1": 44.99, "Grand Master 5": 44.99 },
        "Master 1": { "Grand Master 5": 44.99 },
        "Grand Master 5": { "Grand Master 4": 119.99, "Grand Master 3": 169.99, "Grand Master 2": 229.99, "Grand Master 1": 269.99, "Champion 5": 419.99 },
        "Grand Master 4": { "Grand Master 3": 169.99, "Grand Master 2": 229.99, "Grand Master 1": 269.99, "Champion 5": 419.99 },
        "Grand Master 3": { "Grand Master 2": 229.99, "Grand Master 1": 269.99, "Champion 5": 419.99 },
        "Grand Master 2": { "Grand Master 1": 269.99, "Champion 5": 419.99 },
        "Grand Master 1": { "Champion 5": 419.99 },
        "Champion 5": { "Champion 4": 419.99 }
    };

    const handleOptionChange = (option) => {
        setAdditionalOptions({
            ...additionalOptions,
            [option]: !additionalOptions[option],
        });
        setIsButtonDisabled(true); // Disable button when options are changed
    };

    const handleSliderChange = (e, type) => {
        const value = parseInt(e.target.value, 10);
    
        if (type === 'current') {
            setCurrentLevel(Math.min(value, desiredLevel)); // Limitar current al máximo de desired
        } else if (type === 'desired') {
            setDesiredLevel(Math.max(value, currentLevel)); // Limitar desired al mínimo de current
        }
    };
    
    
    
    

    const handleRankChange = () => {
        setIsButtonDisabled(true); // Disable button when rank is changed
    };

    const calculatePrice = () => {
        let price = 0;
    
        // Verifica qué opción está seleccionada: rankBoost o quickplayBoost
        if (selectedOption === 'rankBoost') {
            let ranks = Object.keys(priceList);
            let startIndex = ranks.indexOf(startRank);
            let endIndex = ranks.indexOf(endRank);
    
            if (startIndex === -1 || endIndex === -1) return;
    
            if (startIndex > endIndex) {
                setErrorMessage('El rango de destino debe ser más alto que el rango de inicio.');
                setTotalPrice(0);
                return;
            }
    
            setErrorMessage('');
    
            let range = ranks.slice(startIndex, endIndex + 1);
    
            for (let i = 0; i < range.length - 1; i++) {
                price += priceList[range[i]][range[i + 1]] || 0;
            }
    
            // Apply additional options pricing for rankBoost
            if (additionalOptions.express) price *= 1.2;
            if (additionalOptions.superExpress) price *= 1.3;
            if (additionalOptions.duoBoost) price *= 1.7;
            if (additionalOptions.specificHeroes) price *= 1.2;
            if (additionalOptions.stream) price *= 1.2;
            if (additionalOptions.oneTrick) price *= 1.4;
            if (additionalOptions.soloOnlyQueue) price *= 1.4;
    
        } else if (selectedOption === 'quickplayBoost') {
            // Calcula el precio para quickplayBoost
            const quickplayPricePerUnit = gameType === 'games' ? 1.25 : 1.50; // 3 USD por Quickplay si es 'games', 5 USD si es 'wins'
            price = quantity * quickplayPricePerUnit;
            setGameTypeChanged(false);  // Restablecer tipo de juego cambiado
            setQuantityChanged(false);  // Restablecer cantidad cambiada
            setPriceCalculated(true);   // Marcar que el precio fue calculado
    
            // Aquí puedes agregar más ajustes de precio si es necesario para quickplayBoost
            if (additionalOptions.express) price *= 1.2;
            if (additionalOptions.superExpress) price *= 1.3;
            if (additionalOptions.duoBoost) price *= 1.7;
            if (additionalOptions.specificHeroes) price *= 1.2;
            if (additionalOptions.stream) price *= 1.2;
            if (additionalOptions.oneTrick) price *= 1.4;
            if (additionalOptions.soloOnlyQueue) price *= 1.4;
        }  else if (selectedOption === 'battlepassBoost') {
            if (currentLevel >= desiredLevel) {
                setPriceCalculated(false);
                return;
            }
    
            setErrorMessage('');
            price = (desiredLevel - currentLevel) * 2;

            if (additionalOptions.express) price *= 1.2;
            if (additionalOptions.superExpress) price *= 1.3;
            if (additionalOptions.duoBoost) price *= 1.7;
            if (additionalOptions.specificHeroes) price *= 1.2;
            if (additionalOptions.stream) price *= 1.2;
            if (additionalOptions.oneTrick) price *= 1.4;
            if (additionalOptions.soloOnlyQueue) price *= 1.4;
    
            setTotalPrice(price.toFixed(2)); // Actualiza el precio
            setPriceCalculated(true);
            setIsButtonDisabled(false); // Habilita el botón
        }else if (selectedOption === 'quickplayBoost') {
            // Calcula el precio para quickplayBoost
            const quickplayPricePerUnit = gameType === 'games' ? 1.25 : 1.50; // 3 USD por Quickplay si es 'games', 5 USD si es 'wins'
            price = quantity * quickplayPricePerUnit;
            setGameTypeChanged(false);  // Restablecer tipo de juego cambiado
            setQuantityChanged(false);  // Restablecer cantidad cambiada
            setPriceCalculated(true);   // Marcar que el precio fue calculado
    
            // Aquí puedes agregar más ajustes de precio si es necesario para quickplayBoost
            if (additionalOptions.express) price *= 1.2;
            if (additionalOptions.superExpress) price *= 1.3;
            if (additionalOptions.duoBoost) price *= 1.7;
            if (additionalOptions.specificHeroes) price *= 1.2;
            if (additionalOptions.stream) price *= 1.2;
            if (additionalOptions.oneTrick) price *= 1.4;
            if (additionalOptions.soloOnlyQueue) price *= 1.4;
        }  else if (selectedOption === 'placementBoost') {
            // Obtiene el precio basado en el rango seleccionado
            price = priceList2[selectedRank] * selectedGames;
    
            // Ajustes de precio adicionales
            if (additionalOptions.express) price *= 1.2;
            if (additionalOptions.superExpress) price *= 1.3;
            if (additionalOptions.duoBoost) price *= 1.7;
            if (additionalOptions.specificHeroes) price *= 1.2;
            if (additionalOptions.stream) price *= 1.2;
            if (additionalOptions.oneTrick) price *= 1.4;
            if (additionalOptions.soloOnlyQueue) price *= 1.4;
    
            setTotalPrice(price.toFixed(2));
            setPriceCalculated(true);
            setIsButtonDisabled(false);
        } else if (selectedOption === 'winBoost') {
            // Obtiene el precio basado en el rango seleccionado
            price = priceList3[selectedRank] * selectedWins;
    
            // Ajustes de precio adicionales
            if (additionalOptions.express) price *= 1.2;
            if (additionalOptions.superExpress) price *= 1.3;
            if (additionalOptions.duoBoost) price *= 1.7;
            if (additionalOptions.specificHeroes) price *= 1.2;
            if (additionalOptions.stream) price *= 1.2;
            if (additionalOptions.oneTrick) price *= 1.4;
            if (additionalOptions.soloOnlyQueue) price *= 1.4;
    
            setTotalPrice(price.toFixed(2));
            setPriceCalculated(true);
            setIsButtonDisabled(false);
        }
     
        setTotalPrice(price.toFixed(2));

        // Verificar si el usuario está logueado antes de habilitar el botón
        const userUsername = localStorage.getItem('userUsername');
        if (userUsername) {
            setIsButtonDisabled(false); // Enable button only if the user is logged in
        } else {
            setIsButtonDisabled(true); // Keep the button disabled if the user is not logged in
        }
    };
    
    const handleOptionChange2 = (option) => {
        setAdditionalOptions((prev) => ({
            ...prev,
            [option]: !prev[option],
        }));
        setIsButtonDisabled(true);
    };

    const handleGamesChange = (event) => {
        setSelectedGames(Number(event.target.value));
        setIsButtonDisabled(true);
    };

    const [expandedItem, setExpandedItem] = useState(null); // Track the expanded FAQ item
    
    const toggleAnswer = (index) => {
        if (expandedItem === index) {
            setExpandedItem(null); // Collapse the currently expanded item if it's clicked again
        } else {
            setExpandedItem(index); // Expand the clicked item
        }
    };

    const adjustLevel = (type, increment) => {
        if (type === 'current') {
            setCurrentLevel((prevLevel) => Math.max(1, Math.min(200, prevLevel + increment)));
        } else if (type === 'desired') {
            setDesiredLevel((prevLevel) => Math.max(1, Math.min(200, prevLevel + increment)));
        }
    };
    
    const handleInputChange = (e, type) => {
        const value = Math.max(1, Math.min(200, e.target.value));
        if (type === 'current') {
            setCurrentLevel(value);
        } else if (type === 'desired') {
            setDesiredLevel(value);
        }
        setPriceCalculated(false); // Resetear flag para recalcular precio
        setIsButtonDisabled(true); // Deshabilitar botón hasta calcular precio
    };
    
    

    return (
        <div className="main-container">
            <Navbar />
            <div className='overwatch-contenedor'>
                <div className='overwatch-titulo'>
                <h1>Overwatch Boost</h1>
                <p>Climb the competitive ladder with confidence, supported by experienced players dedicated to helping you reach new heights.</p>
                </div>
                

                {/* Contenedor principal en tres columnas */}
                <div className='column-overwatch'> 
                    <div className="column-left">
                    <div
                        className="overwatch-option"
                        onClick={() => {
                            setSelectedOption('rankBoost');
                            setTotalPrice(0); // Reset the total price when this option is selected
                            setIsButtonDisabled(true); // Disable the button when this option is selected
                        }}
                        style={{
                            fontWeight: selectedOption === 'rankBoost' ? 'bold' : 'normal',
                            backgroundColor: selectedOption === 'rankBoost' ? '#51cda2' : '#191960' // Change color when selected
                        }}
                    >
                        Rank Boost
                    </div>
                    <div
                        className="overwatch-option"
                        onClick={() => {
                            setSelectedOption('quickplayBoost');
                            setTotalPrice(0); // Reset the total price when this option is selected
                            setIsButtonDisabled(true); // Disable the button when this option is selected
                        }}
                        style={{
                            fontWeight: selectedOption === 'quickplayBoost' ? 'bold' : 'normal',
                            backgroundColor: selectedOption === 'quickplayBoost' ? '#51cda2' : '#191960' // Change color when selected
                        }}
                    >
                        Quickplay Boost
                    </div>
                    <div
                        className="overwatch-option"
                        onClick={() => {
                            setSelectedOption('battlepassBoost');
                            setTotalPrice(0); // Reset the total price when this option is selected
                            setIsButtonDisabled(true); // Disable the button when this option is selected
                        }}
                        style={{
                            fontWeight: selectedOption === 'battlepassBoost' ? 'bold' : 'normal',
                            backgroundColor: selectedOption === 'battlepassBoost' ? '#51cda2' : '#191960' // Change color when selected
                        }}
                    >
                        Battlepass Boost
                    </div>
                    <div
                        className="overwatch-option"
                        onClick={() => {
                            setSelectedOption('placementBoost');
                            setTotalPrice(0); // Reset the total price when this option is selected
                            setIsButtonDisabled(true); // Disable the button when this option is selected
                        }}
                        style={{
                            fontWeight: selectedOption === 'placementBoost' ? 'bold' : 'normal',
                            backgroundColor: selectedOption === 'placementBoost' ? '#51cda2' : '#191960' // Change color when selected
                        }}
                    >
                        Placement Boost
                    </div>
                    <div
                        className="overwatch-option"
                        onClick={() => {
                            setSelectedOption('winBoost');
                            setTotalPrice(0); // Reset the total price when this option is selected
                            setIsButtonDisabled(true); // Disable the button when this option is selected
                        }}
                        style={{
                            fontWeight: selectedOption === 'winBoost' ? 'bold' : 'normal',
                            backgroundColor: selectedOption === 'winBoost' ? '#51cda2' : '#191960' // Change color when selected
                        }}
                    >
                        Win Boost
                    </div>


                    </div>

                    {/* Rank Boost content */}
                    {selectedOption === 'rankBoost' && (
                        <div className="column-right">
                        <div>
                        <div className='overwatch-columns'>
                            {/* Columna 1: Rango de inicio */}
                            <div className='overwatch-seleccion'>
                                <label className='overwatch-label'>Initial Rank:</label>
                                <select
                                    className='overwatch-select'
                                    value={startRank}
                                    onChange={(e) => {
                                        setStartRank(e.target.value);
                                        handleRankChange(); // Handle rank change
                                    }}
                                >
                                    {Object.keys(priceList).map((rank) => (
                                        <option key={rank} value={rank}>{rank}</option>
                                    ))}
                                </select>
                                <img className="rank-logo" src={rankImages[startRank]} alt={startRank} />
                            </div>

                            {/* Flecha Grandde hacia la derecha */}
                            <div className='overwatch-arrow'>
                                <svg width="50" height="50" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9 18l6-6-6-6" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </div>

                            {/* Columna 2: Rango de destino */}
                            <div className='overwatch-seleccion'>
                                <label className='overwatch-label'>Final Rank:</label>
                                <select
                                    className='overwatch-select'
                                    value={endRank}
                                    onChange={(e) => {
                                        setEndRank(e.target.value);
                                        handleRankChange(); // Handle rank change
                                    }}
                                >
                                    {Object.keys(priceList).map((rank) => (
                                        <option key={rank} value={rank}>{rank}</option>
                                    ))}
                                </select>
                                <img className="rank-logo" src={rankImages[endRank]} alt={endRank} />
                            </div>

                            {/* Columna 3: Opciones adicionales */}
                            <div className="overwatch-additional-options">
                                <h3>Additional Options</h3>
                                <label>
                                    <input
                                        type="checkbox"
                                        checked={additionalOptions.express}
                                        onChange={() => handleOptionChange('express')}
                                    />
                                    Express Order (+20%)
                                </label>
                                <label>
                                    <input
                                        type="checkbox"
                                        checked={additionalOptions.superExpress}
                                        onChange={() => handleOptionChange('superExpress')}
                                    />
                                    Super Express (+30%)
                                </label>
                                <label>
                                    <input
                                        type="checkbox"
                                        checked={additionalOptions.duoBoost}
                                        onChange={() => handleOptionChange('duoBoost')}
                                    />
                                    Duo Boost (+70%)
                                </label>
                                <label>
                                    <input
                                        type="checkbox"
                                        checked={additionalOptions.specificHeroes}
                                        onChange={() => handleOptionChange('specificHeroes')}
                                    />
                                    Specific Heroes (+20%)
                                </label>
                                <label>
                                    <input
                                        type="checkbox"
                                        checked={additionalOptions.stream}
                                        onChange={() => handleOptionChange('stream')}
                                    />
                                    Stream (+20%)
                                </label>
                                <label>
                                    <input
                                        type="checkbox"
                                        checked={additionalOptions.oneTrick}
                                        onChange={() => handleOptionChange('oneTrick')}
                                    />
                                    One-Trick (+40%)
                                </label>
                                <label>
                                    <input
                                        type="checkbox"
                                        checked={additionalOptions.soloOnlyQueue}
                                        onChange={() => handleOptionChange('soloOnlyQueue')}
                                    />
                                    Solo Only Queue (+40%)
                                </label>
                            </div>
                            </div>

                            {/* Image selection for roles */}
                            <div className='center-ow'>
                            <div className="overwatch-roles">
                                <div
                                    className={`overwatch-role ${selectedRole === 'dps' ? 'selected' : ''}`}
                                    onClick={() => handleRoleChange('dps')}
                                >
                                    <img src={dps} alt="DPS" />
                                    <span>DPS</span>
                                </div>
                                <div
                                    className={`overwatch-role ${selectedRole === 'healer' ? 'selected' : ''}`}
                                    onClick={() => handleRoleChange('healer')}
                                >
                                    <img src={healer} alt="Healer" />
                                    <span>Healer</span>
                                </div>
                                <div
                                    className={`overwatch-role ${selectedRole === 'tank' ? 'selected' : ''}`}
                                    onClick={() => handleRoleChange('tank')}
                                >
                                    <img src={tank} alt="Tank" />
                                    <span>Tank</span>
                                </div>
                                <div
                                    className={`overwatch-role ${selectedRole === 'open' ? 'selected' : ''}`}
                                    onClick={() => handleRoleChange('open')}
                                >
                                    <img src={open} alt="Open" />
                                    <span>Open queue</span>
                                </div>
                            </div>
                                    
                            {errorMessage && <p className="error-message">{errorMessage}</p>}
                            <button className="overwatch-boton" onClick={calculatePrice}>Calculate Price</button>
                            </div>

                            <div className="overwatch-right-section">
                                <h2 className="overwatch-precio">Total Price: USD ${totalPrice}</h2>
                                <button
                                    className="overwatch-add-to-cart"
                                    disabled={isButtonDisabled}
                                    onClick={handleAddToCart} // Agregar el manejador de eventos aquí
                                >
                                    Add to Cart
                                    {isButtonDisabled && (
                                        <span className="tooltip">You must calculate the price before adding to cart or be logged in.</span>
                                    )}
                                </button>

                            </div>
                        </div>
                        <div className='background-faq'>
                        <div className="overwatch-faq">
                                <h3 className="overwatch-faq-title">FAQ OW2 Boost</h3>

                                <div className="overwatch-faq-item">
                                    <h4
                                        className="overwatch-faq-question"
                                        onClick={() => toggleAnswer(0)} // Toggle for the first question
                                    >
                                        What is OW2 Boost?
                                    </h4>
                                    {expandedItem === 0 && (
                                        <p className="overwatch-faq-answer">
                                            OW2 Boost is a service that helps you increase your rank or level in Overwatch 2 through professional assistance.
                                        </p>
                                    )}
                                </div>

                                <div className="overwatch-faq-item">
                                    <h4
                                        className="overwatch-faq-question"
                                        onClick={() => toggleAnswer(1)} // Toggle for the second question
                                    >
                                        How long does it take to complete a boost?
                                    </h4>
                                    {expandedItem === 1 && (
                                        <p className="overwatch-faq-answer">
                                            The time for completion depends on your current rank and the target rank, but typically it takes between 1 to 7 days.
                                        </p>
                                    )}
                                </div>

                                <div className="overwatch-faq-item">
                                    <h4
                                        className="overwatch-faq-question"
                                        onClick={() => toggleAnswer(2)} // Toggle for the third question
                                    >
                                        Is it safe to use OW2 Boost?
                                    </h4>
                                    {expandedItem === 2 && (
                                        <p className="overwatch-faq-answer">
                                            Yes, our services are 100% safe and comply with Overwatch's terms of service.
                                        </p>
                                    )}
                                </div>

                                <div className="overwatch-faq-item">
                                    <h4
                                        className="overwatch-faq-question"
                                        onClick={() => toggleAnswer(3)} // Toggle for the fourth question
                                    >
                                        Can I track the progress of my boost?
                                    </h4>
                                    {expandedItem === 3 && (
                                        <p className="overwatch-faq-answer">
                                            Yes, you will be able to track your boost progress through our platform in real-time.
                                        </p>
                                    )}
                                </div>

                                <div className="overwatch-faq-item">
                                    <h4
                                        className="overwatch-faq-question"
                                        onClick={() => toggleAnswer(4)} // Toggle for the fifth question
                                    >
                                        Do I need to be online during the boost?
                                    </h4>
                                    {expandedItem === 4 && (
                                        <p className="overwatch-faq-answer">
                                            No, you do not need to be online during the boost. Our professionals will handle the rest.
                                        </p>
                                    )}
                                </div>
                            </div>
                            </div>
                        </div>
                        
                    )}

                    {selectedOption === 'quickplayBoost' && (
                        <div className="column-right">
                            <div className="quickplay-content">
                            <div className="quickplay-left">
                                <p className="quickplay-label">Select game type:</p>
                                <select 
                                    value={gameType} 
                                    onChange={(e) => {
                                        setGameType(e.target.value);
                                        setGameTypeChanged(true); // Marcar que el tipo de juego ha cambiado
                                    }} 
                                    className="quickplay-game-select"
                                >
                                    <option value="games">Games</option>
                                    <option value="wins">Wins</option>
                                </select>
                            </div>


                                <div className="quickplay-right">
                                    <div className='quickplay-center'>
                                        <div className="quickplay-logo">
                                            <img src={quickplay} alt="Quickplay Logo" className="quickplay-logo-img" />
                                        </div>
                                        <div className="quickplay-form">
                                            <h3>How many Quickplays do you want?</h3>
                                            <div className="quickplay-quantity">
                                            <button onClick={() => { 
                                                setQuantity(Math.max(1, quantity - 1));
                                                setQuantityChanged(true); // Marcar que la cantidad ha cambiado
                                            }}> - </button>

                                            <input
                                                type="number"
                                                value={quantity}
                                                onChange={(e) => {
                                                    const value = Math.max(1, Math.min(100, e.target.value));
                                                    setQuantity(value);
                                                    setQuantityChanged(true); // Marcar que la cantidad ha cambiado
                                                }}
                                                min="1"
                                                max="100"
                                                className="quickplay-quantity-input"
                                            />

                                            <button onClick={() => { 
                                                setQuantity(Math.min(100, quantity + 1));
                                                setQuantityChanged(true); // Marcar que la cantidad ha cambiado
                                            }}> + </button>


                                            </div>
                                            <div className="quickplay-buttons">
                                                {errorMessage && <p className="error-message">{errorMessage}</p>}
                                                <button className="overwatch-boton" onClick={calculatePrice}>Calculate Price</button>
                                            </div>
                                        </div>
                                    </div>

                                    
                                </div>
                                <div className="overwatch-additional-options">
                                    <h3>Additional Options</h3>
                                    <label>
                                        <input
                                            type="checkbox"
                                            checked={additionalOptions.express}
                                            onChange={() => handleOptionChange('express')}
                                        />
                                        Express Order (+20%)
                                    </label>
                                    <label>
                                        <input
                                            type="checkbox"
                                            checked={additionalOptions.superExpress}
                                            onChange={() => handleOptionChange('superExpress')}
                                        />
                                        Super Express (+30%)
                                    </label>
                                    <label>
                                    <input
                                        type="checkbox"
                                        checked={additionalOptions.duoBoost}
                                        onChange={() => handleOptionChange('duoBoost')}
                                    />
                                    Duo Boost (+70%)
                                </label>
                                    <label>
                                        <input
                                            type="checkbox"
                                            checked={additionalOptions.specificHeroes}
                                            onChange={() => handleOptionChange('specificHeroes')}
                                        />
                                        Specific Heroes (+20%)
                                    </label>
                                    <label>
                                        <input
                                            type="checkbox"
                                            checked={additionalOptions.stream}
                                            onChange={() => handleOptionChange('stream')}
                                        />
                                        Stream (+20%)
                                    </label>
                                    <label>
                                        <input
                                            type="checkbox"
                                            checked={additionalOptions.oneTrick}
                                            onChange={() => handleOptionChange('oneTrick')}
                                        />
                                        One-Trick (+40%)
                                    </label>
                                    <label>
                                    <input
                                        type="checkbox"
                                        checked={additionalOptions.soloOnlyQueue}
                                        onChange={() => handleOptionChange('soloOnlyQueue')}
                                    />
                                    Solo Only Queue (+40%)
                                </label>
                                </div>
                            </div>
                                <div className="overwatch-right-section-2">
                                    <h2 className="overwatch-precio">Total Price: USD ${totalPrice}</h2>
                                    <button
                                        className="overwatch-add-to-cart"
                                        disabled={isButtonDisabled || !priceCalculated || quantityChanged || gameTypeChanged} // Desactivar si no se calculó el precio o el tipo de juego cambió
                                        onClick={handleAddToCart}
                                    >
                                        Add to Cart
                                        {isButtonDisabled && (
                                            <span className="tooltip">You must calculate the price before adding to cart or be logged in.</span>
                                        )}
                                    </button>



                                </div>
                                <div className='background-faq'>
                        <div className="overwatch-faq">
                                <h3 className="overwatch-faq-title">FAQ OW2 Boost</h3>

                                <div className="overwatch-faq-item">
                                    <h4
                                        className="overwatch-faq-question"
                                        onClick={() => toggleAnswer(0)} // Toggle for the first question
                                    >
                                        What is OW2 Boost?
                                    </h4>
                                    {expandedItem === 0 && (
                                        <p className="overwatch-faq-answer">
                                            OW2 Boost is a service that helps you increase your rank or level in Overwatch 2 through professional assistance.
                                        </p>
                                    )}
                                </div>

                                <div className="overwatch-faq-item">
                                    <h4
                                        className="overwatch-faq-question"
                                        onClick={() => toggleAnswer(1)} // Toggle for the second question
                                    >
                                        How long does it take to complete a boost?
                                    </h4>
                                    {expandedItem === 1 && (
                                        <p className="overwatch-faq-answer">
                                            The time for completion depends on your current rank and the target rank, but typically it takes between 1 to 7 days.
                                        </p>
                                    )}
                                </div>

                                <div className="overwatch-faq-item">
                                    <h4
                                        className="overwatch-faq-question"
                                        onClick={() => toggleAnswer(2)} // Toggle for the third question
                                    >
                                        Is it safe to use OW2 Boost?
                                    </h4>
                                    {expandedItem === 2 && (
                                        <p className="overwatch-faq-answer">
                                            Yes, our services are 100% safe and comply with Overwatch's terms of service.
                                        </p>
                                    )}
                                </div>

                                <div className="overwatch-faq-item">
                                    <h4
                                        className="overwatch-faq-question"
                                        onClick={() => toggleAnswer(3)} // Toggle for the fourth question
                                    >
                                        Can I track the progress of my boost?
                                    </h4>
                                    {expandedItem === 3 && (
                                        <p className="overwatch-faq-answer">
                                            Yes, you will be able to track your boost progress through our platform in real-time.
                                        </p>
                                    )}
                                </div>

                                <div className="overwatch-faq-item">
                                    <h4
                                        className="overwatch-faq-question"
                                        onClick={() => toggleAnswer(4)} // Toggle for the fifth question
                                    >
                                        Do I need to be online during the boost?
                                    </h4>
                                    {expandedItem === 4 && (
                                        <p className="overwatch-faq-answer">
                                            No, you do not need to be online during the boost. Our professionals will handle the rest.
                                        </p>
                                    )}
                                </div>
                            </div>
                            </div>
                        </div>
                    )}

                    {selectedOption === 'battlepassBoost' && (
                        <div className="column-right">
                            <div className="battlepass-content">

                                <div className="battlepass-form">
                                    
                                    {/* Current and Desired Levels */}
                                    <div className='battlepass-center'> 
                                        
                                    <div className="battlepass-level-inputs">
                                        
                                    {/* Current Level */}
                                    <label className="battlepass-label">
                                        Current Battlepass Level:
                                        <div className="input-container">
                                            <button className="input-button" onClick={() => adjustLevel('current', -1)}>-</button>
                                            <input
                                                type="number"
                                                className="battlepass-input"
                                                value={currentLevel}
                                                onChange={(e) => handleInputChange(e, 'current')}
                                                min="1"
                                                max="200"
                                            />
                                            <button className="input-button" onClick={() => adjustLevel('current', 1)}>+</button>
                                        </div>
                                        {/* Slider para Current Level */}
                                        <input
                                            type="range"
                                            className="battlepass-slider current-slider"
                                            min="1"
                                            max="200"
                                            step="1"
                                            value={currentLevel}
                                            onChange={(e) => handleSliderChange(e, 'current')}
                                        />
                                    </label>

                                    {/* Desired Level */}
                                    <label className="battlepass-label">
                                        Desired Battlepass Level:
                                        <div className="input-container">
                                            <button className="input-button" onClick={() => adjustLevel('desired', -1)}>-</button>
                                            <input
                                                type="number"
                                                className="battlepass-input"
                                                value={desiredLevel}
                                                onChange={(e) => handleInputChange(e, 'desired')}
                                                min="1"
                                                max="200"
                                            />
                                            <button className="input-button" onClick={() => adjustLevel('desired', 1)}>+</button>
                                        </div>
                                        {/* Slider para Desired Level */}
                                        <input
                                            type="range"
                                            className="battlepass-slider desired-slider"
                                            min="1"
                                            max="200"
                                            step="1"
                                            value={desiredLevel}
                                            onChange={(e) => handleSliderChange(e, 'desired')}
                                        />
                                    </label>
                                </div>
                                    
                                {/* Additional Options */}
                                <div className="overwatch-additional-options opciones">
                                    <h3>Additional Options</h3>
                                    <label>
                                        <input
                                            type="checkbox"
                                            checked={additionalOptions.express}
                                            onChange={() => handleOptionChange('express')}
                                        />
                                        Express Order (+20%)
                                    </label>
                                    <label>
                                        <input
                                            type="checkbox"
                                            checked={additionalOptions.superExpress}
                                            onChange={() => handleOptionChange('superExpress')}
                                        />
                                        Super Express (+30%)
                                    </label>
                                    <label>
                                    <input
                                        type="checkbox"
                                        checked={additionalOptions.duoBoost}
                                        onChange={() => handleOptionChange('duoBoost')}
                                    />
                                    Duo Boost (+70%)
                                </label>
                                    <label>
                                        <input
                                            type="checkbox"
                                            checked={additionalOptions.specificHeroes}
                                            onChange={() => handleOptionChange('specificHeroes')}
                                        />
                                        Specific Heroes (+20%)
                                    </label>
                                    <label>
                                        <input
                                            type="checkbox"
                                            checked={additionalOptions.stream}
                                            onChange={() => handleOptionChange('stream')}
                                        />
                                        Stream (+20%)
                                    </label>
                                    <label>
                                        <input
                                            type="checkbox"
                                            checked={additionalOptions.oneTrick}
                                            onChange={() => handleOptionChange('oneTrick')}
                                        />
                                        One-Trick (+40%)
                                    </label>
                                    <label>
                                    <input
                                        type="checkbox"
                                        checked={additionalOptions.soloOnlyQueue}
                                        onChange={() => handleOptionChange('soloOnlyQueue')}
                                    />
                                    Solo Only Queue (+40%)
                                </label>
                                </div>
                                </div>
                                <img className="rank-logo-battlepass" src={battlepass} alt={startRank} />
                                    {/* Quickplay Buttons */}
                                    <div className="quickplay-buttons">
                                        {errorMessage && <p className="error-message">{errorMessage}</p>}
                                        <button
                                            className="overwatch-boton2"
                                            onClick={calculatePrice}
                                            disabled={!!errorMessage || currentLevel >= desiredLevel}
                                        >
                                            Calculate Price
                                        </button>
                                    </div>
                                </div>



                                {/* Price and Add to Cart */}
                                <div className="overwatch-right-section-3">
                                    <h2 className="overwatch-precio">Total Price: USD ${totalPrice}</h2>
                                    <button
                                        className="overwatch-add-to-cart"
                                        disabled={
                                            isButtonDisabled || // Se asegura de que el botón se desactive manualmente
                                            !priceCalculated || 
                                            !!errorMessage || 
                                            currentLevel >= desiredLevel
                                        }
                                        onClick={handleAddToCart}
                                    >
                                        Add to Cart
                                        {(isButtonDisabled || !priceCalculated || !!errorMessage) && (
                                            <span className="tooltip">
                                                You must calculate the price before adding to cart or fix the levels.
                                            </span>
                                        )}
                                    </button>


                                </div>
                            </div>
                            <div className='background-faq'>
                        <div className="overwatch-faq2">
                                <h3 className="overwatch-faq-title">FAQ OW2 Boost</h3>

                                <div className="overwatch-faq-item">
                                    <h4
                                        className="overwatch-faq-question"
                                        onClick={() => toggleAnswer(0)} // Toggle for the first question
                                    >
                                        What is OW2 Boost?
                                    </h4>
                                    {expandedItem === 0 && (
                                        <p className="overwatch-faq-answer">
                                            OW2 Boost is a service that helps you increase your rank or level in Overwatch 2 through professional assistance.
                                        </p>
                                    )}
                                </div>

                                <div className="overwatch-faq-item">
                                    <h4
                                        className="overwatch-faq-question"
                                        onClick={() => toggleAnswer(1)} // Toggle for the second question
                                    >
                                        How long does it take to complete a boost?
                                    </h4>
                                    {expandedItem === 1 && (
                                        <p className="overwatch-faq-answer">
                                            The time for completion depends on your current rank and the target rank, but typically it takes between 1 to 7 days.
                                        </p>
                                    )}
                                </div>

                                <div className="overwatch-faq-item">
                                    <h4
                                        className="overwatch-faq-question"
                                        onClick={() => toggleAnswer(2)} // Toggle for the third question
                                    >
                                        Is it safe to use OW2 Boost?
                                    </h4>
                                    {expandedItem === 2 && (
                                        <p className="overwatch-faq-answer">
                                            Yes, our services are 100% safe and comply with Overwatch's terms of service.
                                        </p>
                                    )}
                                </div>

                                <div className="overwatch-faq-item">
                                    <h4
                                        className="overwatch-faq-question"
                                        onClick={() => toggleAnswer(3)} // Toggle for the fourth question
                                    >
                                        Can I track the progress of my boost?
                                    </h4>
                                    {expandedItem === 3 && (
                                        <p className="overwatch-faq-answer">
                                            Yes, you will be able to track your boost progress through our platform in real-time.
                                        </p>
                                    )}
                                </div>

                                <div className="overwatch-faq-item">
                                    <h4
                                        className="overwatch-faq-question"
                                        onClick={() => toggleAnswer(4)} // Toggle for the fifth question
                                    >
                                        Do I need to be online during the boost?
                                    </h4>
                                    {expandedItem === 4 && (
                                        <p className="overwatch-faq-answer">
                                            No, you do not need to be online during the boost. Our professionals will handle the rest.
                                        </p>
                                    )}
                                </div>
                            </div>
                            </div>
                        </div>
                    )}

                    {selectedOption === 'placementBoost' && (
                        <div className="column-right">
                            <div>
                                <div className='overwatch-columns'>
                                    {/* Columna 1: Rango seleccionado */}
                                    <div className='overwatch-seleccion'>
                                        <label className='overwatch-label'>Last Known Tier in Previous Season:</label>
                                        <select
                                            className='overwatch-select'
                                            value={selectedRank}
                                            onChange={(e) => {
                                                setSelectedRank(e.target.value);
                                                setIsButtonDisabled(true); // Desactiva el botón al cambiar el rango
                                            }}
                                        >
                                            {Object.keys(priceList2).map((rank) => (
                                                <option key={rank} value={rank}>{rank}</option>
                                            ))}
                                        </select>
                                        <img className="rank-logo" src={rankImages2[selectedRank]} alt={selectedRank} />
                                    </div>

                                    {/* Columna 2: Juegos seleccionados */}
                                    <div className='overwatch-seleccion'>
                                        <img 
                                            src={placement} 
                                            alt="Placement" 
                                            className="placement-image"
                                        />
                                        <label className='overwatch-label'>Number of Games:</label>
                                        <div className="overwatch-slider-container">
                                            <div className="placement-selected-games-box">
                                                <span>{selectedGames}</span>
                                            </div>
                                            <input
                                                className="overwatch-range"
                                                type="range"
                                                min="1"
                                                max="10"
                                                value={selectedGames}
                                                onChange={(e) => {
                                                    setSelectedGames(Number(e.target.value));
                                                    setIsButtonDisabled(true); // Desactiva el botón al cambiar los juegos
                                                }}
                                            />
                                        </div>
                                        <h2 className='win-rate-text'>WIN RATE GUARANTEE 75%</h2>
                                    </div>



                                {/* Columna 3: Opciones adicionales */}
                                <div className="overwatch-additional-options">
                                    <h3>Additional Options</h3>
                                    <label>
                                        <input
                                            type="checkbox"
                                            checked={additionalOptions.express}
                                            onChange={() => handleOptionChange('express')}
                                        />
                                        Express Order (+20%)
                                    </label>
                                    <label>
                                        <input
                                            type="checkbox"
                                            checked={additionalOptions.superExpress}
                                            onChange={() => handleOptionChange('superExpress')}
                                        />
                                        Super Express (+30%)
                                    </label>
                                    <label>
                                        <input
                                            type="checkbox"
                                            checked={additionalOptions.duoBoost}
                                            onChange={() => handleOptionChange('duoBoost')}
                                        />
                                        Duo Boost (+70%)
                                    </label>
                                    <label>
                                        <input
                                            type="checkbox"
                                            checked={additionalOptions.specificHeroes}
                                            onChange={() => handleOptionChange('specificHeroes')}
                                        />
                                        Specific Heroes (+20%)
                                    </label>
                                    <label>
                                        <input
                                            type="checkbox"
                                            checked={additionalOptions.stream}
                                            onChange={() => handleOptionChange('stream')}
                                        />
                                        Stream (+20%)
                                    </label>
                                    <label>
                                        <input
                                            type="checkbox"
                                            checked={additionalOptions.oneTrick}
                                            onChange={() => handleOptionChange('oneTrick')}
                                        />
                                        One-Trick (+40%)
                                    </label>
                                    <label>
                                    <input
                                        type="checkbox"
                                        checked={additionalOptions.soloOnlyQueue}
                                        onChange={() => handleOptionChange('soloOnlyQueue')}
                                    />
                                    Solo Only Queue (+40%)
                                </label>
                                </div>
                            </div>

                            {/* Botón para calcular precio */}
                            <div className="center-ow">
                            <div className="overwatch-roles">
                                <div
                                    className={`overwatch-role ${selectedRole === 'dps' ? 'selected' : ''}`}
                                    onClick={() => handleRoleChange('dps')}
                                >
                                    <img src={dps} alt="DPS" />
                                    <span>DPS</span>
                                </div>
                                <div
                                    className={`overwatch-role ${selectedRole === 'healer' ? 'selected' : ''}`}
                                    onClick={() => handleRoleChange('healer')}
                                >
                                    <img src={healer} alt="Healer" />
                                    <span>Healer</span>
                                </div>
                                <div
                                    className={`overwatch-role ${selectedRole === 'tank' ? 'selected' : ''}`}
                                    onClick={() => handleRoleChange('tank')}
                                >
                                    <img src={tank} alt="Tank" />
                                    <span>Tank</span>
                                </div>
                                <div
                                    className={`overwatch-role ${selectedRole === 'open' ? 'selected' : ''}`}
                                    onClick={() => handleRoleChange('open')}
                                >
                                    <img src={open} alt="Open" />
                                    <span>Open queue</span>
                                </div>
                            </div>
                                <button className="overwatch-boton" onClick={calculatePrice}>
                                    Calculate Price
                                </button>
                            </div>

                            {/* Sección de precio y agregar al carrito */}
                            <div className="overwatch-right-section">
                                <h2 className="overwatch-precio">Total Price: USD ${totalPrice}</h2>
                                <button
                                    className="overwatch-add-to-cart"
                                    disabled={isButtonDisabled}
                                    onClick={handleAddToCart}
                                >
                                    Add to Cart
                                    {isButtonDisabled && (
                                        <span className="tooltip">You must calculate the price before adding to cart or be logged in.</span>
                                    )}
                                </button>
                            </div>
                        </div>
                        <div className='background-faq back-faq'>
                        <div className="overwatch-faq2">
                                <h3 className="overwatch-faq-title">FAQ OW2 Boost</h3>

                                <div className="overwatch-faq-item">
                                    <h4
                                        className="overwatch-faq-question"
                                        onClick={() => toggleAnswer(0)} // Toggle for the first question
                                    >
                                        What is OW2 Boost?
                                    </h4>
                                    {expandedItem === 0 && (
                                        <p className="overwatch-faq-answer">
                                            OW2 Boost is a service that helps you increase your rank or level in Overwatch 2 through professional assistance.
                                        </p>
                                    )}
                                </div>

                                <div className="overwatch-faq-item">
                                    <h4
                                        className="overwatch-faq-question"
                                        onClick={() => toggleAnswer(1)} // Toggle for the second question
                                    >
                                        How long does it take to complete a boost?
                                    </h4>
                                    {expandedItem === 1 && (
                                        <p className="overwatch-faq-answer">
                                            The time for completion depends on your current rank and the target rank, but typically it takes between 1 to 7 days.
                                        </p>
                                    )}
                                </div>

                                <div className="overwatch-faq-item">
                                    <h4
                                        className="overwatch-faq-question"
                                        onClick={() => toggleAnswer(2)} // Toggle for the third question
                                    >
                                        Is it safe to use OW2 Boost?
                                    </h4>
                                    {expandedItem === 2 && (
                                        <p className="overwatch-faq-answer">
                                            Yes, our services are 100% safe and comply with Overwatch's terms of service.
                                        </p>
                                    )}
                                </div>

                                <div className="overwatch-faq-item">
                                    <h4
                                        className="overwatch-faq-question"
                                        onClick={() => toggleAnswer(3)} // Toggle for the fourth question
                                    >
                                        Can I track the progress of my boost?
                                    </h4>
                                    {expandedItem === 3 && (
                                        <p className="overwatch-faq-answer">
                                            Yes, you will be able to track your boost progress through our platform in real-time.
                                        </p>
                                    )}
                                </div>

                                <div className="overwatch-faq-item">
                                    <h4
                                        className="overwatch-faq-question"
                                        onClick={() => toggleAnswer(4)} // Toggle for the fifth question
                                    >
                                        Do I need to be online during the boost?
                                    </h4>
                                    {expandedItem === 4 && (
                                        <p className="overwatch-faq-answer">
                                            No, you do not need to be online during the boost. Our professionals will handle the rest.
                                        </p>
                                    )}
                                </div>
                            </div>
                            </div>
                    </div>
                )}

                    {selectedOption === 'winBoost' && (<div className="column-right2">
                            <div>
                                <div className='overwatch-columns'>
                                    {/* Columna 1: Rango seleccionado */}
                                    <div className='overwatch-seleccion'>
                                        <label className='overwatch-label'>Current Rank:</label>
                                        <select
                                            className='overwatch-select'
                                            value={selectedRank}
                                            onChange={(e) => {
                                                setSelectedRank(e.target.value);
                                                setIsButtonDisabled(true); // Desactiva el botón al cambiar el rango
                                            }}
                                        >
                                            {Object.keys(priceList3).map((rank) => (
                                                <option key={rank} value={rank}>{rank}</option>
                                            ))}
                                        </select>
                                        <img className="rank-logo" src={rankImages3[selectedRank]} alt={selectedRank} />
                                    </div>

                                    {/* Columna 2: Juegos seleccionados */}
                                    <div className='overwatch-seleccion'>
                                    <img 
                                            src={wins} 
                                            alt="Wins" 
                                            className="wins-image"
                                        />
                                        <label className='overwatch-label'>Number of Games:</label>
                                        <div className="overwatch-slider-container">
                                            <div className="placement-selected-games-box">
                                                <span>{selectedWins}</span>
                                            </div>
                                            <input
                                                className="overwatch-range"
                                                type="range"
                                                min="1"
                                                max="5"
                                                value={selectedWins}
                                                onChange={(e) => {
                                                    setSelectedWins(Number(e.target.value));
                                                    setIsButtonDisabled(true); // Desactiva el botón al cambiar los juegos
                                                }}
                                            />
                                        </div>
                                        <h2 className='win-rate-text'>WIN RATE GUARANTEE 100%</h2>
                                    </div>



                                {/* Columna 3: Opciones adicionales */}
                                <div className="overwatch-additional-options">
                                    <h3>Additional Options</h3>
                                    <label>
                                        <input
                                            type="checkbox"
                                            checked={additionalOptions.express}
                                            onChange={() => handleOptionChange('express')}
                                        />
                                        Express Order (+20%)
                                    </label>
                                    <label>
                                        <input
                                            type="checkbox"
                                            checked={additionalOptions.superExpress}
                                            onChange={() => handleOptionChange('superExpress')}
                                        />
                                        Super Express (+30%)
                                    </label>
                                    <label>
                                        <input
                                            type="checkbox"
                                            checked={additionalOptions.duoBoost}
                                            onChange={() => handleOptionChange('duoBoost')}
                                        />
                                        Duo Boost (+70%)
                                    </label>
                                    <label>
                                        <input
                                            type="checkbox"
                                            checked={additionalOptions.specificHeroes}
                                            onChange={() => handleOptionChange('specificHeroes')}
                                        />
                                        Specific Heroes (+20%)
                                    </label>
                                    <label>
                                        <input
                                            type="checkbox"
                                            checked={additionalOptions.stream}
                                            onChange={() => handleOptionChange('stream')}
                                        />
                                        Stream (+20%)
                                    </label>
                                    <label>
                                        <input
                                            type="checkbox"
                                            checked={additionalOptions.oneTrick}
                                            onChange={() => handleOptionChange('oneTrick')}
                                        />
                                        One-Trick (+40%)
                                    </label>
                                    <label>
                                    <input
                                        type="checkbox"
                                        checked={additionalOptions.soloOnlyQueue}
                                        onChange={() => handleOptionChange('soloOnlyQueue')}
                                    />
                                    Solo Only Queue (+40%)
                                </label>
                                </div>
                            </div>

                            {/* Botón para calcular precio */}
                            <div className="center-ow">
                            <div className="overwatch-roles">
                                <div
                                    className={`overwatch-role ${selectedRole === 'dps' ? 'selected' : ''}`}
                                    onClick={() => handleRoleChange('dps')}
                                >
                                    <img src={dps} alt="DPS" />
                                    <span>DPS</span>
                                </div>
                                <div
                                    className={`overwatch-role ${selectedRole === 'healer' ? 'selected' : ''}`}
                                    onClick={() => handleRoleChange('healer')}
                                >
                                    <img src={healer} alt="Healer" />
                                    <span>Healer</span>
                                </div>
                                <div
                                    className={`overwatch-role ${selectedRole === 'tank' ? 'selected' : ''}`}
                                    onClick={() => handleRoleChange('tank')}
                                >
                                    <img src={tank} alt="Tank" />
                                    <span>Tank</span>
                                </div>
                                <div
                                    className={`overwatch-role ${selectedRole === 'open' ? 'selected' : ''}`}
                                    onClick={() => handleRoleChange('open')}
                                >
                                    <img src={open} alt="Open" />
                                    <span>Open queue</span>
                                </div>
                            </div>
                                <button className="overwatch-boton" onClick={calculatePrice}>
                                    Calculate Price
                                </button>
                            </div>

                            {/* Sección de precio y agregar al carrito */}
                            <div className="overwatch-right-section">
                                <h2 className="overwatch-precio">Total Price: USD ${totalPrice}</h2>
                                <button
                                    className="overwatch-add-to-cart"
                                    disabled={isButtonDisabled}
                                    onClick={handleAddToCart}
                                >
                                    Add to Cart
                                    {isButtonDisabled && (
                                        <span className="tooltip">You must calculate the price before adding to cart or be logged in.</span>
                                    )}
                                </button>
                            </div>
                        </div>
                        <div className='background-faq back-faq'>
                        <div className="overwatch-faq2">
                                <h3 className="overwatch-faq-title">FAQ OW2 Boost</h3>

                                <div className="overwatch-faq-item">
                                    <h4
                                        className="overwatch-faq-question"
                                        onClick={() => toggleAnswer(0)} // Toggle for the first question
                                    >
                                        What is OW2 Boost?
                                    </h4>
                                    {expandedItem === 0 && (
                                        <p className="overwatch-faq-answer">
                                            OW2 Boost is a service that helps you increase your rank or level in Overwatch 2 through professional assistance.
                                        </p>
                                    )}
                                </div>

                                <div className="overwatch-faq-item">
                                    <h4
                                        className="overwatch-faq-question"
                                        onClick={() => toggleAnswer(1)} // Toggle for the second question
                                    >
                                        How long does it take to complete a boost?
                                    </h4>
                                    {expandedItem === 1 && (
                                        <p className="overwatch-faq-answer">
                                            The time for completion depends on your current rank and the target rank, but typically it takes between 1 to 7 days.
                                        </p>
                                    )}
                                </div>

                                <div className="overwatch-faq-item">
                                    <h4
                                        className="overwatch-faq-question"
                                        onClick={() => toggleAnswer(2)} // Toggle for the third question
                                    >
                                        Is it safe to use OW2 Boost?
                                    </h4>
                                    {expandedItem === 2 && (
                                        <p className="overwatch-faq-answer">
                                            Yes, our services are 100% safe and comply with Overwatch's terms of service.
                                        </p>
                                    )}
                                </div>

                                <div className="overwatch-faq-item">
                                    <h4
                                        className="overwatch-faq-question"
                                        onClick={() => toggleAnswer(3)} // Toggle for the fourth question
                                    >
                                        Can I track the progress of my boost?
                                    </h4>
                                    {expandedItem === 3 && (
                                        <p className="overwatch-faq-answer">
                                            Yes, you will be able to track your boost progress through our platform in real-time.
                                        </p>
                                    )}
                                </div>

                                <div className="overwatch-faq-item">
                                    <h4
                                        className="overwatch-faq-question"
                                        onClick={() => toggleAnswer(4)} // Toggle for the fifth question
                                    >
                                        Do I need to be online during the boost?
                                    </h4>
                                    {expandedItem === 4 && (
                                        <p className="overwatch-faq-answer">
                                            No, you do not need to be online during the boost. Our professionals will handle the rest.
                                        </p>
                                    )}
                                </div>
                            </div>
                            </div>
                    </div>)}
            </div>
            </div>
            <Footer />
        </div>
    );
};

export default Overwatch;
