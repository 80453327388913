import React from 'react';
import Navbar from './navbar'; // Asegúrate de que la ruta de importación sea correcta
import '../styles/admin.css'; // Si tienes estilos específicos para Admin
import Footer from './footer'; // Importa el Footer

function Admin() {
    return (
        <div className='main-container'>
            <Navbar />
            <div className="panel-admin-container">
                <div className="panel-admin-welcome">
                    <h1 className="panel-admin-title">¡Bienvenido, Admin!</h1>
                    <p className="panel-admin-subtitle">
                        Este es tu panel de control para gestionar usuarios, ventas, y boosts de Overwatch.
                    </p>
                </div>
                <div className="panel-admin-content">
                    <div className="panel-admin-card">
                        <h3>Usuarios Registrados</h3>
                        <p>Gestiona y supervisa las cuentas de tus usuarios de forma eficiente.</p>
                    </div>
                    <div className="panel-admin-card">
                        <h3>Ventas de Overwatch</h3>
                        <p>Revisa el historial de ventas y gestiona las transacciones de tus servicios.</p>
                    </div>
                    <div className="panel-admin-card">
                        <h3>Boosts Activos</h3>
                        <p>Monitorea los boosts activos y asigna nuevos pedidos a los jugadores profesionales.</p>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Admin;
