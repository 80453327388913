import React, { useState, useEffect } from 'react';
import Footer from './footer'; // Importa el Footer
import Navbar from './navbar'; // Importa el Navbar
import '../styles/usuarios.css'; // Importa el archivo de estilos

const Usuarios = () => {
    const [clientes, setClientes] = useState([]); // Estado para los usuarios
    const [loading, setLoading] = useState(true); // Estado de carga
    const [error, setError] = useState(null); // Estado de errores
    const [currentPage, setCurrentPage] = useState(1); // Página actual
    const itemsPerPage = 8; // Número de usuarios por página

    useEffect(() => {
        const fetchClientes = async () => {
            try {
                const response = await fetch('https://alienboost.onrender.com/clientes');
                if (!response.ok) {
                    throw new Error('Error al obtener los clientes');
                }
                const data = await response.json();
                setClientes(data.clientes); // Guarda los clientes en el estado
            } catch (error) {
                setError(error.message); // Guarda el mensaje de error
            } finally {
                setLoading(false); // Finaliza el estado de carga
            }
        };

        fetchClientes();
    }, []);

    // Calcular los usuarios a mostrar según la página actual
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentClientes = clientes.slice(indexOfFirstItem, indexOfLastItem);

    // Cambiar de página
    const handleNextPage = () => {
        if (currentPage < Math.ceil(clientes.length / itemsPerPage)) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    return (
        <div className="main-container">
            <Navbar />
            <div className="contenedor-principal-cliente">
                <h1 className="titulo-cliente">Clientes</h1>
                {loading ? (
                    <p className="mensaje-cargando-cliente">Cargando clientes...</p>
                ) : error ? (
                    <p className="mensaje-error-cliente">Error: {error}</p>
                ) : (
                    <>
                        <table className="tabla-clientes">
                            <thead>
                                <tr>
                                    <th className="encabezado-cliente">ID</th>
                                    <th className="encabezado-cliente">Correo</th>
                                    <th className="encabezado-cliente">Username</th>
                                    <th className="encabezado-cliente">Nombre</th>
                                    <th className="encabezado-cliente">Apellido</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentClientes.map((cliente) => (
                                    <tr key={cliente.id} className="fila-cliente">
                                        <td className="celda-cliente">{cliente.id}</td>
                                        <td className="celda-cliente">{cliente.correo}</td>
                                        <td className="celda-cliente">{cliente.username}</td>
                                        <td className="celda-cliente">{cliente.nombre}</td>
                                        <td className="celda-cliente">{cliente.apellido}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <div className="paginacion-cliente">
                            <button
                                className="flecha-cliente"
                                onClick={handlePrevPage}
                                disabled={currentPage === 1}
                            >
                                &lt;
                            </button>
                            <span className="pagina-actual-cliente">
                                Página {currentPage} de {Math.ceil(clientes.length / itemsPerPage)}
                            </span>
                            <button
                                className="flecha-cliente"
                                onClick={handleNextPage}
                                disabled={currentPage === Math.ceil(clientes.length / itemsPerPage)}
                            >
                                &gt;
                            </button>
                        </div>
                    </>
                )}
            </div>
            <Footer />
        </div>
    );
};

export default Usuarios;
