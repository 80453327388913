/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/cuentas.css';
import Navbar from './navbar';
import Footer from './footer';
import respaldo from '../img/respaldo.jpg'; // Importa el logo

const Cuentas = () => {
    const [ventas, setVentas] = useState([]);
    const [filteredVentas, setFilteredVentas] = useState([]);
    const [loading, setLoading] = useState(true);
    const [filters, setFilters] = useState({
        dps: false,
        tank: false,
        healer: false,
        rankedReady: false,
        gm: false,
        master: false,
        champion: false,
    });

    const navigate = useNavigate();

    const fetchVentas = async () => {
        try {
            const response = await fetch('https://alienboost.onrender.com/ventas');
            if (!response.ok) {
                throw new Error('Error al obtener las ventas');
            }
            const data = await response.json();

            const filteredVentas = data.filter(venta => venta.status === 2 || venta.status === 3);

            const ventasConDisponibilidad = await Promise.all(
                filteredVentas.map(async (venta) => {
                    const isTaken = await checkIfCartExists(venta.id);
                    return {
                        ...venta,
                        isTaken
                    };
                })
            );

            ventasConDisponibilidad.sort((a, b) => a.isTaken - b.isTaken);

            setVentas(ventasConDisponibilidad);
            setFilteredVentas(ventasConDisponibilidad);
            setLoading(false);
        } catch (error) {
            console.error('Error:', error);
            setLoading(false);
        }
    };

    const checkIfCartExists = async (id) => {
        try {
            const response = await fetch(`https://alienboost.onrender.com/existe-carrito?venta_id=${id}`);
            if (!response.ok) {
                throw new Error('Error al verificar si existe un carrito');
            }
            const data = await response.json();
            return data.existe;
        } catch (error) {
            console.error('Error:', error);
            return false;
        }
    };

    useEffect(() => {
        fetchVentas();
    }, []);

    const applyFilters = () => {
        let filtered = ventas;
        if (filters.dps) {
            filtered = filtered.filter(venta => venta.dps_rank !== "0");
        }
        if (filters.tank) {
            filtered = filtered.filter(venta => venta.tank_rank !== "0");
        }
        if (filters.healer) {
            filtered = filtered.filter(venta => venta.support_rank !== "0");
        }
        if (filters.rankedReady) {
            filtered = filtered.filter(venta => venta.ranked_ready === 'yes');
        }
        if (filters.gm) {
            filtered = filtered.filter(venta =>
                Object.values(venta).some(value =>
                    typeof value === 'string' && /gm|grandmaster/i.test(value)
                )
            );
        }
        if (filters.master) {
            filtered = filtered.filter(venta =>
                Object.values(venta).some(value =>
                    typeof value === 'string' && /master/i.test(value)
                )
            );
        }
        if (filters.champion) {
            filtered = filtered.filter(venta =>
                Object.values(venta).some(value =>
                    typeof value === 'string' && /champ|champion/i.test(value)
                )
            );
        }
        

        setFilteredVentas(filtered);
    };

    const handleFilterChange = (filterName) => {
        setFilters((prev) => {
            const updatedFilters = { ...prev, [filterName]: !prev[filterName] };
            return updatedFilters;
        });
    };

    useEffect(() => {
        applyFilters();
    }, [filters]);

    if (loading) {
        return (
            <div className="loading-spinner">
                <div className="spinner"></div>
            </div>
        );
    }

    return (
        <div className="main-container">
            <Navbar />
            <div className="filter-container">
                <label>
                    <input
                        type="checkbox"
                        checked={filters.dps}
                        onChange={() => handleFilterChange('dps')}
                    />
                    DPS
                </label>
                <label>
                    <input
                        type="checkbox"
                        checked={filters.tank}
                        onChange={() => handleFilterChange('tank')}
                    />
                    Tank
                </label>
                <label>
                    <input
                        type="checkbox"
                        checked={filters.healer}
                        onChange={() => handleFilterChange('healer')}
                    />
                    Healer
                </label>
                <label>
                    <input
                        type="checkbox"
                        checked={filters.rankedReady}
                        onChange={() => handleFilterChange('rankedReady')}
                    />
                    Ranked Ready
                </label>
                <label>
                    <input
                        type="checkbox"
                        checked={filters.gm}
                        onChange={() => handleFilterChange('gm')}
                    />
                    GM
                </label>
                <label>
                    <input
                        type="checkbox"
                        checked={filters.master}
                        onChange={() => handleFilterChange('master')}
                    />
                    Master
                </label>
                <label>
                    <input
                        type="checkbox"
                        checked={filters.champion}
                        onChange={() => handleFilterChange('champion')}
                    />
                    Champion
                </label>
            </div>
            <div className="ventas-container">
                {filteredVentas.map((venta) => (
                    <div 
                        key={venta.id} 
                        className="card" 
                        onClick={() => navigate(`/card/${venta.id}`)}
                    >
                        <img
                            src={venta.image}
                            alt={venta.game}
                            className="card-image"
                            onError={(e) => { e.target.src = respaldo; }} // Si falla la carga, usa la imagen de respaldo
                        />

                        <div className="card-info">
                            <h3>{venta.title}</h3>
                            <p>USD ${venta.price.toFixed(2)}</p>
                        </div>
                    </div>
                ))}
            </div>
            <Footer />
        </div>
    );
};

export default Cuentas;
