/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from './navbar';
import Footer from './footer';
import profile1 from '../img/profile1.jpg';
import profile2 from '../img/profile2.jpg';
import profile3 from '../img/profile3.jpg';
import profile4 from '../img/profile4.jpg';
import profile5 from '../img/profile5.jpg';
import profile6 from '../img/profile6.jpg';
import '../styles/mi-cuenta.css';

function MiCuenta() {
    const navigate = useNavigate();
    const [userData, setUserData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [profileImage, setProfileImage] = useState('');
    const [isEditing, setIsEditing] = useState(false);
    const [email, setEmail] = useState('');
    const [username, setUsername] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false); // Control del modal

    const profileImages = [profile1, profile2, profile3, profile4, profile5, profile6];

    const handleLogout = () => {
        localStorage.removeItem('userId');
        navigate('/');
    };

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const userId = localStorage.getItem('userId');
                if (!userId) throw new Error('User ID not found in local storage.');

                const response = await fetch(`https://alienboost.onrender.com/users/${userId}`);
                if (!response.ok) throw new Error('Failed to fetch account data.');

                const data = await response.json();
                setUserData(data);

                // Usar la foto de perfil del usuario o una predeterminada
                if (data.foto_perfil) {
                    setProfileImage(data.foto_perfil);
                } else {
                    setProfileImage(profile1);
                }

                setEmail(data.correo);
                setUsername(data.username);
                setFirstName(data.nombre);
                setLastName(data.apellido);
            } catch (err) {
                setError(err.message);
                setProfileImage(profileImages[Math.floor(Math.random() * profileImages.length)]); // Imagen aleatoria en caso de error
            } finally {
                setLoading(false);
            }
        };

        fetchUserData();
    }, []);

    const handleImageError = () => {
        setProfileImage(profileImages[Math.floor(Math.random() * profileImages.length)]); // Asignar una imagen aleatoria en caso de error
    };

    const handleEditClick = () => {
        console.log('Edit mode activated'); // Verifica que se active
        setIsEditing(true);
    };

    const handleAcceptClick = async () => {
        try {
            const updatedUser = {
                correo: email,
                username: username,
                nombre: firstName,
                apellido: lastName,
                foto_perfil: profileImage, // Enviar la nueva foto seleccionada
            };

            const response = await fetch(`https://alienboost.onrender.com/editar-usuario/${userData.id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(updatedUser),
            });

            if (!response.ok) {
                const errorData = await response.json();
                setError(errorData.detail || 'Error updating user');
                throw new Error(errorData.detail || 'Error updating user');
            }

            const result = await response.json();
            console.log(result.message);

            setUserData((prevData) => ({
                ...prevData,
                correo: updatedUser.correo,
                username: updatedUser.username,
                nombre: updatedUser.nombre,
                apellido: updatedUser.apellido,
                foto_perfil: updatedUser.foto_perfil,
            }));

            setIsEditing(false);
            setError('');
        } catch (error) {
            console.error('Error updating account:', error);
            setError('Error al actualizar la cuenta. Por favor, inténtalo de nuevo.');
        }
    };

    const handleCancelClick = () => {
        setIsEditing(false);
        if (userData) {
            setEmail(userData.correo);
            setUsername(userData.username);
            setFirstName(userData.nombre);
            setLastName(userData.apellido);
            setProfileImage(userData.foto_perfil || profile1); // Restaurar la imagen anterior
        }
    };

    const handleImageClick = () => setIsModalOpen(true); // Abrir el modal

    const handleImageSelect = (image) => {
        setProfileImage(image);
        setIsModalOpen(false); // Cerrar el modal
    };

    const handleDeleteClick = async () => {
        if (window.confirm("Are you sure you want to delete this account?")) {
            try {
                const userId = localStorage.getItem('userId');
                const response = await fetch(`https://alienboost.onrender.com/users/${userId}`, {
                    method: 'DELETE',
                });

                if (!response.ok) throw new Error('Failed to delete account.');

                alert("Account deleted successfully. You have been logged out.");
                handleLogout();
            } catch (err) {
                alert("Error deleting account: " + err.message);
            }
        }
    };

    if (loading) {
        return (
            <div className="loading-spinner">
                <div className="spinner"></div>
            </div>
        );
    }

    return (
        <div className="main-container">
            <Navbar />
            <div className="mi-cuenta-container">
                <h2 className="mi-cuenta-title">My Account</h2>
                {error ? (
                    <p className="error-message">{error}</p>
                ) : (
                    <div className="account-info">
                        <div className="profile-image-container">
                            <img
                                src={profileImage}
                                alt="Profile"
                                className="profile-image"
                                onError={handleImageError} // Manejar errores de imagen
                            />
                            {isEditing && (
                                <span className="edit-icon" onClick={handleImageClick}>
                                    🖉
                                </span>
                            )}
                        </div>

                        <p>
                            <strong>Email: </strong>
                            {isEditing ? (
                                <input type="text" value={email} onChange={(e) => setEmail(e.target.value)} />
                            ) : (
                                <span>{email}</span>
                            )}
                        </p>
                        <p>
                            <strong>Username: </strong>
                            {isEditing ? (
                                <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} />
                            ) : (
                                <span>{username}</span>
                            )}
                        </p>
                        <p>
                            <strong>First Name: </strong>
                            {isEditing ? (
                                <input type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                            ) : (
                                <span>{firstName}</span>
                            )}
                        </p>
                        <p>
                            <strong>Last Name: </strong>
                            {isEditing ? (
                                <input type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                            ) : (
                                <span>{lastName}</span>
                            )}
                        </p>

                        {!isEditing ? (
                            <button className="mi-cuenta-edit-button" onClick={handleEditClick}>
                                Edit Account
                            </button>
                        ) : (
                            <>
                                <button className="mi-cuenta-accept-button" onClick={handleAcceptClick}>
                                    Accept
                                </button>
                                <button className="mi-cuenta-cancel-button" onClick={handleCancelClick}>
                                    Cancel
                                </button>
                                <button className="mi-cuenta-delete-button" onClick={handleDeleteClick}>
                                    Delete Account
                                </button>
                            </>
                        )}
                    </div>
                )}
            </div>

            {isModalOpen && (
                <div className="profile-modal">
                    <div className="profile-modal-content">
                        <h3>Select a Profile Picture</h3>
                        <div className="profile-modal-images">
                            {profileImages.map((image, index) => (
                                <img
                                    key={index}
                                    src={image}
                                    alt={`Profile ${index}`}
                                    className="profile-modal-image"
                                    onClick={() => handleImageSelect(image)}
                                />
                            ))}
                        </div>
                        <button className="profile-modal-close" onClick={() => setIsModalOpen(false)}>
                            Close
                        </button>
                    </div>
                </div>
            )}

            <Footer />
        </div>
    );
}

export default MiCuenta;
