import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import '../styles/cuentadetalle.css';
import Footer from './footer'; // Importa el Footer
import Navbar from './navbar';
import afiche from '../img/afiche.png';

const CuentaDetalle = () => {
    const { id } = useParams();
    const [venta, setVenta] = useState(null);
    const [isAddingToCart, setIsAddingToCart] = useState(false);
    const [isCartExists, setIsCartExists] = useState(false);  // Nueva variable para verificar si el carrito existe
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchVenta = async () => {
            try {
                const response = await fetch(`https://alienboost.onrender.com/ventas/${id}`);
                if (!response.ok) {
                    throw new Error('Error al obtener los detalles de la venta');
                }
                const data = await response.json();
                setVenta(data);
            } catch (error) {
                console.error('Error:', error);
            } finally {
                setLoading(false); // Aseguramos que el estado de carga se actualice
            }
        };
    
        const checkIfCartExists = async () => {
            try {
                const response = await fetch(`https://alienboost.onrender.com/existe-carrito?venta_id=${id}`);
                if (!response.ok) {
                    throw new Error('Error al verificar si existe un carrito');
                }
                const data = await response.json();
                setIsCartExists(data.existe); // Actualizamos el estado según la respuesta
            } catch (error) {
                console.error('Error:', error);
            } finally {
                setLoading(false); // Aseguramos que el estado de carga se actualice
            }
        };
    
        // Ejecutamos ambas funciones de forma independiente
        setLoading(true); // Indicamos que la carga inicia
        fetchVenta();
        checkIfCartExists();
    
    }, [id]); // Dependemos del cambio en `id`
    

    const handleAddToCart = async (precio) => {
        console.log('Botón presionado, el precio es:', precio);
        setIsAddingToCart(true);  // Indicar que se está agregando al carrito
    
        try {
            const userId = localStorage.getItem('userId');
            if (!userId) {
                throw new Error('Usuario no autenticado');
            }
    
            const requestBody = {
                usuario_id: userId,  // Usamos el userId del localStorage
                precio: precio,  // Recibimos el precio como parámetro
                venta_id: id,  // El id de la venta obtenido de los parámetros de la URL
            };
    
            console.log('Enviando al backend:', requestBody);
    
            const response = await fetch('https://alienboost.onrender.com/crear-carrito', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestBody),
            });
    
            if (!response.ok) {
                throw new Error('Error al agregar al carrito');
            }
    
            const data = await response.json();
            console.log('Carrito actualizado:', data);
    
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setIsAddingToCart(false);  // Terminar de agregar al carrito
            window.location.reload();  // Refrescar la página
        }
    };
    
            // Mostrar únicamente el spinner mientras carga
            if (loading) {
                return (
                    <div className="loading-spinner">
                        <div className="spinner"></div>
                    </div>
                );
            }
    
    return (
        <div>
            <Navbar />
            {venta ? (
                <div className="container">
                    <div className='principal-info'>
                        <div className="image-container">
                            <img src={afiche} alt="Afiche" />
                            <p className="gallery-container">
                                <a href={venta.galery} target="_blank" rel="noopener noreferrer" className="gallery-link">
                                    View Image Gallery
                                </a>
                            </p>
                        </div>
                        <div className="details">
                            <h2>{venta.title}</h2>
                            <p className={`platform ${venta.platform === 1 ? 'pc' : 'consola'}`}>
                                {venta.platform === 1 ? 'PC' : 'Console'}
                            </p>
                            <p className="price">USD ${venta.price.toFixed(2)}</p>
                            
                            <button 
                                className="add-to-cart-button" 
                                onClick={() => handleAddToCart(venta.price.toFixed(2))}  
                                disabled={isAddingToCart || isCartExists}  // Deshabilitar si el carrito existe o si se está añadiendo
                            >
                                {isCartExists ? 'Sold Out' : (isAddingToCart ? 'Adding...' : 'Add to Cart')}
                            </button>

                            <p className="terms-of-service">
                                <b>Terms of service:</b> By using our services, you confirm that your account is entirely legal and secure. We prioritize your privacy and are here to assist you with any inquiries or concerns. Please note that once the warranty period has ended, we cannot be held responsible for any account bans, theft, or other issues that may arise. Your account security is ultimately your responsibility. For any questions or support, don’t hesitate to contact us at any time. Thank you for choosing us!
                            </p>
                        </div>
                    </div>

                    <h3 className="table-title">Accounts Specifications</h3>
                    <div className="table-container">
                        <div className="table-section">
                            <table>
                                <tbody>
                                    <tr>
                                        <td className="info-title">Battle Tag</td>
                                        <td>{venta.battle_tag}</td>
                                    </tr>
                                    <tr>
                                        <td className="info-title">DPS Rank</td>
                                        <td>{venta.dps_rank}</td>
                                    </tr>
                                    <tr>
                                        <td className="info-title">Tank Rank</td>
                                        <td>{venta.tank_rank}</td>
                                    </tr>
                                    <tr>
                                        <td className="info-title">Support Rank</td>
                                        <td>{venta.support_rank}</td>
                                    </tr>
                                    <tr>
                                        <td className="info-title">OW1 Coins</td>
                                        <td>{venta.ow1_coins}</td>
                                    </tr>
                                    <tr>
                                        <td className="info-title">Paid Currency Coins</td>
                                        <td>{venta.paid_currency_coins}</td>
                                    </tr>
                                    <tr>
                                        <td className="info-title">Golden Gun Coins</td>
                                        <td>{venta.golden_gun_coins}</td>
                                    </tr>
                                    <tr>
                                        <td className="info-title">OWL Tokens</td>
                                        <td>{venta.overwatch_league_tokens}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div className="table-section">
                            <table>
                                <tbody>
                                    <tr>
                                        <td className="info-title">Golden Guns</td>
                                        <td>{venta.golden_guns}</td>
                                    </tr>
                                    <tr>
                                        <td className="info-title">Battle Pass Level</td>
                                        <td>{venta.battle_pass_level}</td>
                                    </tr>
                                    <tr>
                                        <td className="info-title">Rare Skins</td>
                                        <td>{venta.rare_skins}</td>
                                    </tr>
                                    <tr>
                                        <td className="info-title">Level</td>
                                        <td>{venta.level}</td>
                                    </tr>
                                    <tr>
                                        <td className="info-title">Ranked Ready</td>
                                        <td>{venta.ranked_ready}</td>
                                    </tr>
                                    <tr>
                                        <td className="info-title">Account Type</td>
                                        <td>{venta.account_type}</td>
                                    </tr>
                                    <tr>
                                        <td className="info-title">Battle Tag Change</td>
                                        <td>{venta.battle_tag_change}</td>
                                    </tr>
                                    <tr>
                                        <td className="info-title">Fake ID</td>
                                        <td>{venta.fake_id}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            ) : (
                <p>Loading...</p>
            )}
         <Footer />
        </div>
    );
};

export default CuentaDetalle;
